import { makeProviderMark as Pt, makeProp as k, Fragment as X, OnUnmount as q, WithProvider as Dt, UseProvider as Wt, renderableOfTNode as it, Signal as Mt, html as Ft, on as Nt, Value as rt, attr as Vt, Empty as ht, OneOfType as $t, makeSignal as ie, Portal as zt, isSSR as re, OnMount as Ht, When as Ut, OneOfTuple as se, makeComputedOf as ce, Prop as le } from "@tempots/dom";
const ae = (t) => {
  const e = t.split("/").pop();
  if (e == null || e.startsWith(".")) return;
  const n = e.split(".") || [];
  return n.length > 1 ? "." + n.pop() : void 0;
}, ue = (t, e) => {
  const n = ae(e);
  return n != null && (t.length === 0 || !t.some((o) => n == o));
}, fe = (t, e, n, o) => {
  let i = t.target;
  for (; i != null && !(i instanceof HTMLAnchorElement); )
    i = i.parentElement;
  if (i == null)
    return !0;
  const r = i;
  if (t.button !== 0 || t.ctrlKey || t.metaKey || r.target !== "_self" && r.target !== "" || r.getAttribute("download") != null)
    return !0;
  const { pathname: s, search: c, hash: a } = r;
  if (o) {
    const l = s + c + a, u = r.getAttribute("href");
    if (!(u != null && u.startsWith("#")) && u !== l)
      return !0;
  }
  return e ? !1 : ue(n, s);
}, he = (t, e = {
  ignoreUrlWithExtension: !0,
  allowedExtensions: [],
  ignoreExternalUrl: !0
}) => {
  const n = e.ignoreUrlWithExtension === !0 && Array.isArray(e.allowedExtensions) ? e.allowedExtensions.map(
    (o) => o.startsWith(".") ? o : "." + o
  ) : [];
  return (o) => {
    fe(
      o,
      e.ignoreUrlWithExtension ?? !0,
      n,
      e.ignoreExternalUrl ?? !0
    ) || t() && o.preventDefault();
  };
}, _t = Pt("LocationProvider"), de = () => {
  const t = (window == null ? void 0 : window.location.hash) === "" ? void 0 : (window == null ? void 0 : window.location.hash.substring(1)) ?? void 0;
  return {
    pathname: (window == null ? void 0 : window.location.pathname) ?? "",
    search: Object.fromEntries(
      new URLSearchParams((window == null ? void 0 : window.location.search) ?? "").entries()
    ),
    hash: t
  };
}, me = (t, e) => t.pathname === e.pathname && JSON.stringify(t.search) === JSON.stringify(e.search) && t.hash === e.hash, pe = (t) => {
  const e = new URL(t, (window == null ? void 0 : window.location.toString()) ?? ""), n = Object.fromEntries(e.searchParams.entries());
  let o = e.hash;
  return o.startsWith("#") && (o = o.substring(1)), {
    pathname: e.pathname,
    search: n,
    hash: o === "" ? void 0 : o
  };
}, ge = (t, e) => {
  const n = pe(e);
  return t.set(n), t;
}, we = (t) => {
  const n = new URLSearchParams(t.search).toString(), o = t.hash;
  return `${t.pathname}${n ? `?${n}` : ""}${o ? `#${o}` : ""}`;
}, ye = () => {
  const t = k(de(), me), e = () => {
    let n = (window == null ? void 0 : window.location.hash) ?? "";
    n.startsWith("#") && (n = n.substring(1));
    const o = {
      pathname: (window == null ? void 0 : window.location.pathname) ?? "",
      search: Object.fromEntries(
        new URLSearchParams((window == null ? void 0 : window.location.search) ?? "").entries()
      ),
      hash: n === "" ? void 0 : n
    };
    t.set(o);
  };
  return window == null || window.addEventListener("popstate", e), t.onDispose(() => {
    window == null || window.removeEventListener("popstate", e);
  }), t.on((n) => {
    window == null || window.history.pushState({}, "", we(n));
  }), t;
}, dn = (t) => {
  const e = ye();
  return X(
    q(e.dispose),
    Dt(_t, e, t)
  );
}, Bt = (t) => Wt(_t, (e) => (n) => {
  const o = k(e.value, e.equals);
  e.feedProp(o), o.on(e.set);
  const i = it(t(o))(n);
  return (r) => {
    o.dispose(), i(r);
  };
}), ve = (t, ...e) => {
  if (typeof t == "string" || Mt.is(t))
    return ve({ href: t }, ...e);
  const { href: n, ...o } = t;
  return Bt((i) => Ft.a(
    Nt.click(
      he(() => (ge(i, rt.get(n)), !0), o)
    ),
    Vt.href(n),
    ...e
  ));
}, It = Pt("Appearance"), mn = (t) => {
  const e = window.matchMedia != null && window.matchMedia("(prefers-color-scheme: dark)").matches, n = k(e ? "dark" : "light"), o = (r) => {
    n.set(r.matches ? "dark" : "light");
  }, i = window.matchMedia != null ? window.matchMedia("(prefers-color-scheme: dark)") : void 0;
  return i == null || i.addEventListener("change", o), X(
    Dt(It, n, t),
    q(() => i == null ? void 0 : i.removeEventListener("change", o))
  );
}, pn = (t) => Wt(It, t), xe = (t, e) => {
  if (typeof e == "function")
    return xe(t, { success: e });
  const n = e.failure ?? ((s) => X(
    q(s.on(console.error)),
    s.map((c) => `Error: ${c}`)
  )), o = e.success, i = e.loading ?? (() => ht), r = e.notAsked ?? (() => ht);
  return $t(rt.toSignal(t), {
    AsyncSuccess: (s) => o(s.$.value),
    AsyncFailure: (s) => n(s.$.error),
    Loading: (s) => i(s.$.previousValue ?? ie(void 0)),
    NotAsked: r
  });
}, gn = (t = 10) => (e) => {
  const n = setTimeout(() => {
    var o;
    (o = e.element) == null || o.focus();
  }, t);
  return (o) => clearTimeout(n);
}, wn = (t = 10) => (e) => {
  const n = setTimeout(() => {
    var o;
    (o = e.element) == null || o.select();
  }, t);
  return (o) => {
    clearTimeout(n);
  };
}, yn = (t) => {
  const e = t.element, n = e.style.getPropertyValue(":empty");
  return e.style.setProperty(":empty", "display:none"), (o) => {
    o && e.style.setProperty(":empty", n);
  };
}, vn = (t) => zt("head > title", Vt.innerText(t)), be = {
  partial: {
    root: null,
    rootMargin: "0px",
    threshold: 0
  },
  full: {
    root: null,
    rootMargin: "0px",
    threshold: 1
  }
}, Q = {
  partial: /* @__PURE__ */ new Map(),
  full: /* @__PURE__ */ new Map()
}, I = {
  partial: null,
  full: null
};
function Ae(t) {
  return I[t] == null && (I[t] = new IntersectionObserver((e) => {
    e.forEach((n) => {
      const o = Q[t].get(n.target);
      o == null || o.set(n.isIntersecting);
    });
  }, be[t])), I[t];
}
const Ee = (t, e) => {
  const n = k(re());
  return X(
    Ht((o) => {
      const i = typeof IntersectionObserver < "u" ? Ae(t) : null;
      return Q[t].set(o, n), i == null || i.observe(o), () => {
        var r;
        i == null || i.unobserve(o), Q[t].delete(o), Q[t].size === 0 && ((r = I[t]) == null || r.disconnect(), I[t] = null);
      };
    }),
    q(n.dispose),
    it(e(n))
  );
}, xn = (t, e, n) => Ee(t, (o) => Ut(o, e, n ?? ht)), Z = Math.min, W = Math.max, tt = Math.round, G = Math.floor, C = (t) => ({
  x: t,
  y: t
}), Re = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, Le = {
  start: "end",
  end: "start"
};
function At(t, e, n) {
  return W(t, Z(e, n));
}
function st(t, e) {
  return typeof t == "function" ? t(e) : t;
}
function M(t) {
  return t.split("-")[0];
}
function ct(t) {
  return t.split("-")[1];
}
function jt(t) {
  return t === "x" ? "y" : "x";
}
function Yt(t) {
  return t === "y" ? "height" : "width";
}
function z(t) {
  return ["top", "bottom"].includes(M(t)) ? "y" : "x";
}
function Xt(t) {
  return jt(z(t));
}
function Oe(t, e, n) {
  n === void 0 && (n = !1);
  const o = ct(t), i = Xt(t), r = Yt(i);
  let s = i === "x" ? o === (n ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return e.reference[r] > e.floating[r] && (s = et(s)), [s, et(s)];
}
function Se(t) {
  const e = et(t);
  return [dt(t), e, dt(e)];
}
function dt(t) {
  return t.replace(/start|end/g, (e) => Le[e]);
}
function Te(t, e, n) {
  const o = ["left", "right"], i = ["right", "left"], r = ["top", "bottom"], s = ["bottom", "top"];
  switch (t) {
    case "top":
    case "bottom":
      return n ? e ? i : o : e ? o : i;
    case "left":
    case "right":
      return e ? r : s;
    default:
      return [];
  }
}
function ke(t, e, n, o) {
  const i = ct(t);
  let r = Te(M(t), n === "start", o);
  return i && (r = r.map((s) => s + "-" + i), e && (r = r.concat(r.map(dt)))), r;
}
function et(t) {
  return t.replace(/left|right|bottom|top/g, (e) => Re[e]);
}
function Ce(t) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  };
}
function Pe(t) {
  return typeof t != "number" ? Ce(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function nt(t) {
  const {
    x: e,
    y: n,
    width: o,
    height: i
  } = t;
  return {
    width: o,
    height: i,
    top: n,
    left: e,
    right: e + o,
    bottom: n + i,
    x: e,
    y: n
  };
}
function Et(t, e, n) {
  let {
    reference: o,
    floating: i
  } = t;
  const r = z(e), s = Xt(e), c = Yt(s), a = M(e), l = r === "y", u = o.x + o.width / 2 - i.width / 2, f = o.y + o.height / 2 - i.height / 2, m = o[c] / 2 - i[c] / 2;
  let h;
  switch (a) {
    case "top":
      h = {
        x: u,
        y: o.y - i.height
      };
      break;
    case "bottom":
      h = {
        x: u,
        y: o.y + o.height
      };
      break;
    case "right":
      h = {
        x: o.x + o.width,
        y: f
      };
      break;
    case "left":
      h = {
        x: o.x - i.width,
        y: f
      };
      break;
    default:
      h = {
        x: o.x,
        y: o.y
      };
  }
  switch (ct(e)) {
    case "start":
      h[s] -= m * (n && l ? -1 : 1);
      break;
    case "end":
      h[s] += m * (n && l ? -1 : 1);
      break;
  }
  return h;
}
const De = async (t, e, n) => {
  const {
    placement: o = "bottom",
    strategy: i = "absolute",
    middleware: r = [],
    platform: s
  } = n, c = r.filter(Boolean), a = await (s.isRTL == null ? void 0 : s.isRTL(e));
  let l = await s.getElementRects({
    reference: t,
    floating: e,
    strategy: i
  }), {
    x: u,
    y: f
  } = Et(l, o, a), m = o, h = {}, p = 0;
  for (let g = 0; g < c.length; g++) {
    const {
      name: w,
      fn: d
    } = c[g], {
      x: y,
      y: v,
      data: b,
      reset: x
    } = await d({
      x: u,
      y: f,
      initialPlacement: o,
      placement: m,
      strategy: i,
      middlewareData: h,
      rects: l,
      platform: s,
      elements: {
        reference: t,
        floating: e
      }
    });
    u = y ?? u, f = v ?? f, h = {
      ...h,
      [w]: {
        ...h[w],
        ...b
      }
    }, x && p <= 50 && (p++, typeof x == "object" && (x.placement && (m = x.placement), x.rects && (l = x.rects === !0 ? await s.getElementRects({
      reference: t,
      floating: e,
      strategy: i
    }) : x.rects), {
      x: u,
      y: f
    } = Et(l, m, a)), g = -1);
  }
  return {
    x: u,
    y: f,
    placement: m,
    strategy: i,
    middlewareData: h
  };
};
async function qt(t, e) {
  var n;
  e === void 0 && (e = {});
  const {
    x: o,
    y: i,
    platform: r,
    rects: s,
    elements: c,
    strategy: a
  } = t, {
    boundary: l = "clippingAncestors",
    rootBoundary: u = "viewport",
    elementContext: f = "floating",
    altBoundary: m = !1,
    padding: h = 0
  } = st(e, t), p = Pe(h), w = c[m ? f === "floating" ? "reference" : "floating" : f], d = nt(await r.getClippingRect({
    element: (n = await (r.isElement == null ? void 0 : r.isElement(w))) == null || n ? w : w.contextElement || await (r.getDocumentElement == null ? void 0 : r.getDocumentElement(c.floating)),
    boundary: l,
    rootBoundary: u,
    strategy: a
  })), y = f === "floating" ? {
    x: o,
    y: i,
    width: s.floating.width,
    height: s.floating.height
  } : s.reference, v = await (r.getOffsetParent == null ? void 0 : r.getOffsetParent(c.floating)), b = await (r.isElement == null ? void 0 : r.isElement(v)) ? await (r.getScale == null ? void 0 : r.getScale(v)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, x = nt(r.convertOffsetParentRelativeRectToViewportRelativeRect ? await r.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements: c,
    rect: y,
    offsetParent: v,
    strategy: a
  }) : y);
  return {
    top: (d.top - x.top + p.top) / b.y,
    bottom: (x.bottom - d.bottom + p.bottom) / b.y,
    left: (d.left - x.left + p.left) / b.x,
    right: (x.right - d.right + p.right) / b.x
  };
}
const We = function(t) {
  return t === void 0 && (t = {}), {
    name: "flip",
    options: t,
    async fn(e) {
      var n, o;
      const {
        placement: i,
        middlewareData: r,
        rects: s,
        initialPlacement: c,
        platform: a,
        elements: l
      } = e, {
        mainAxis: u = !0,
        crossAxis: f = !0,
        fallbackPlacements: m,
        fallbackStrategy: h = "bestFit",
        fallbackAxisSideDirection: p = "none",
        flipAlignment: g = !0,
        ...w
      } = st(t, e);
      if ((n = r.arrow) != null && n.alignmentOffset)
        return {};
      const d = M(i), y = z(c), v = M(c) === c, b = await (a.isRTL == null ? void 0 : a.isRTL(l.floating)), x = m || (v || !g ? [et(c)] : Se(c)), N = p !== "none";
      !m && N && x.push(...ke(c, g, p, b));
      const ne = [c, ...x], ut = await qt(e, w), J = [];
      let _ = ((o = r.flip) == null ? void 0 : o.overflows) || [];
      if (u && J.push(ut[d]), f) {
        const D = Oe(i, s, b);
        J.push(ut[D[0]], ut[D[1]]);
      }
      if (_ = [..._, {
        placement: i,
        overflows: J
      }], !J.every((D) => D <= 0)) {
        var yt, vt;
        const D = (((yt = r.flip) == null ? void 0 : yt.index) || 0) + 1, bt = ne[D];
        if (bt)
          return {
            data: {
              index: D,
              overflows: _
            },
            reset: {
              placement: bt
            }
          };
        let B = (vt = _.filter((V) => V.overflows[0] <= 0).sort((V, S) => V.overflows[1] - S.overflows[1])[0]) == null ? void 0 : vt.placement;
        if (!B)
          switch (h) {
            case "bestFit": {
              var xt;
              const V = (xt = _.filter((S) => {
                if (N) {
                  const T = z(S.placement);
                  return T === y || // Create a bias to the `y` side axis due to horizontal
                  // reading directions favoring greater width.
                  T === "y";
                }
                return !0;
              }).map((S) => [S.placement, S.overflows.filter((T) => T > 0).reduce((T, oe) => T + oe, 0)]).sort((S, T) => S[1] - T[1])[0]) == null ? void 0 : xt[0];
              V && (B = V);
              break;
            }
            case "initialPlacement":
              B = c;
              break;
          }
        if (i !== B)
          return {
            reset: {
              placement: B
            }
          };
      }
      return {};
    }
  };
};
async function Me(t, e) {
  const {
    placement: n,
    platform: o,
    elements: i
  } = t, r = await (o.isRTL == null ? void 0 : o.isRTL(i.floating)), s = M(n), c = ct(n), a = z(n) === "y", l = ["left", "top"].includes(s) ? -1 : 1, u = r && a ? -1 : 1, f = st(e, t);
  let {
    mainAxis: m,
    crossAxis: h,
    alignmentAxis: p
  } = typeof f == "number" ? {
    mainAxis: f,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...f
  };
  return c && typeof p == "number" && (h = c === "end" ? p * -1 : p), a ? {
    x: h * u,
    y: m * l
  } : {
    x: m * l,
    y: h * u
  };
}
const Fe = function(t) {
  return t === void 0 && (t = 0), {
    name: "offset",
    options: t,
    async fn(e) {
      var n, o;
      const {
        x: i,
        y: r,
        placement: s,
        middlewareData: c
      } = e, a = await Me(e, t);
      return s === ((n = c.offset) == null ? void 0 : n.placement) && (o = c.arrow) != null && o.alignmentOffset ? {} : {
        x: i + a.x,
        y: r + a.y,
        data: {
          ...a,
          placement: s
        }
      };
    }
  };
}, Ne = function(t) {
  return t === void 0 && (t = {}), {
    name: "shift",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o,
        placement: i
      } = e, {
        mainAxis: r = !0,
        crossAxis: s = !1,
        limiter: c = {
          fn: (w) => {
            let {
              x: d,
              y
            } = w;
            return {
              x: d,
              y
            };
          }
        },
        ...a
      } = st(t, e), l = {
        x: n,
        y: o
      }, u = await qt(e, a), f = z(M(i)), m = jt(f);
      let h = l[m], p = l[f];
      if (r) {
        const w = m === "y" ? "top" : "left", d = m === "y" ? "bottom" : "right", y = h + u[w], v = h - u[d];
        h = At(y, h, v);
      }
      if (s) {
        const w = f === "y" ? "top" : "left", d = f === "y" ? "bottom" : "right", y = p + u[w], v = p - u[d];
        p = At(y, p, v);
      }
      const g = c.fn({
        ...e,
        [m]: h,
        [f]: p
      });
      return {
        ...g,
        data: {
          x: g.x - n,
          y: g.y - o
        }
      };
    }
  };
};
function U(t) {
  return Kt(t) ? (t.nodeName || "").toLowerCase() : "#document";
}
function A(t) {
  var e;
  return (t == null || (e = t.ownerDocument) == null ? void 0 : e.defaultView) || window;
}
function O(t) {
  var e;
  return (e = (Kt(t) ? t.ownerDocument : t.document) || window.document) == null ? void 0 : e.documentElement;
}
function Kt(t) {
  return t instanceof Node || t instanceof A(t).Node;
}
function R(t) {
  return t instanceof Element || t instanceof A(t).Element;
}
function L(t) {
  return t instanceof HTMLElement || t instanceof A(t).HTMLElement;
}
function Rt(t) {
  return typeof ShadowRoot > "u" ? !1 : t instanceof ShadowRoot || t instanceof A(t).ShadowRoot;
}
function K(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: o,
    display: i
  } = E(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + o + n) && !["inline", "contents"].includes(i);
}
function Ve(t) {
  return ["table", "td", "th"].includes(U(t));
}
function lt(t) {
  return [":popover-open", ":modal"].some((e) => {
    try {
      return t.matches(e);
    } catch {
      return !1;
    }
  });
}
function pt(t) {
  const e = gt(), n = E(t);
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !e && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !e && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((o) => (n.willChange || "").includes(o)) || ["paint", "layout", "strict", "content"].some((o) => (n.contain || "").includes(o));
}
function $e(t) {
  let e = P(t);
  for (; L(e) && !H(e); ) {
    if (lt(e))
      return null;
    if (pt(e))
      return e;
    e = P(e);
  }
  return null;
}
function gt() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function H(t) {
  return ["html", "body", "#document"].includes(U(t));
}
function E(t) {
  return A(t).getComputedStyle(t);
}
function at(t) {
  return R(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.scrollX,
    scrollTop: t.scrollY
  };
}
function P(t) {
  if (U(t) === "html")
    return t;
  const e = (
    // Step into the shadow DOM of the parent of a slotted node.
    t.assignedSlot || // DOM Element detected.
    t.parentNode || // ShadowRoot detected.
    Rt(t) && t.host || // Fallback.
    O(t)
  );
  return Rt(e) ? e.host : e;
}
function Jt(t) {
  const e = P(t);
  return H(e) ? t.ownerDocument ? t.ownerDocument.body : t.body : L(e) && K(e) ? e : Jt(e);
}
function j(t, e, n) {
  var o;
  e === void 0 && (e = []), n === void 0 && (n = !0);
  const i = Jt(t), r = i === ((o = t.ownerDocument) == null ? void 0 : o.body), s = A(i);
  return r ? e.concat(s, s.visualViewport || [], K(i) ? i : [], s.frameElement && n ? j(s.frameElement) : []) : e.concat(i, j(i, [], n));
}
function Gt(t) {
  const e = E(t);
  let n = parseFloat(e.width) || 0, o = parseFloat(e.height) || 0;
  const i = L(t), r = i ? t.offsetWidth : n, s = i ? t.offsetHeight : o, c = tt(n) !== r || tt(o) !== s;
  return c && (n = r, o = s), {
    width: n,
    height: o,
    $: c
  };
}
function wt(t) {
  return R(t) ? t : t.contextElement;
}
function $(t) {
  const e = wt(t);
  if (!L(e))
    return C(1);
  const n = e.getBoundingClientRect(), {
    width: o,
    height: i,
    $: r
  } = Gt(e);
  let s = (r ? tt(n.width) : n.width) / o, c = (r ? tt(n.height) : n.height) / i;
  return (!s || !Number.isFinite(s)) && (s = 1), (!c || !Number.isFinite(c)) && (c = 1), {
    x: s,
    y: c
  };
}
const ze = /* @__PURE__ */ C(0);
function Qt(t) {
  const e = A(t);
  return !gt() || !e.visualViewport ? ze : {
    x: e.visualViewport.offsetLeft,
    y: e.visualViewport.offsetTop
  };
}
function He(t, e, n) {
  return e === void 0 && (e = !1), !n || e && n !== A(t) ? !1 : e;
}
function F(t, e, n, o) {
  e === void 0 && (e = !1), n === void 0 && (n = !1);
  const i = t.getBoundingClientRect(), r = wt(t);
  let s = C(1);
  e && (o ? R(o) && (s = $(o)) : s = $(t));
  const c = He(r, n, o) ? Qt(r) : C(0);
  let a = (i.left + c.x) / s.x, l = (i.top + c.y) / s.y, u = i.width / s.x, f = i.height / s.y;
  if (r) {
    const m = A(r), h = o && R(o) ? A(o) : o;
    let p = m, g = p.frameElement;
    for (; g && o && h !== p; ) {
      const w = $(g), d = g.getBoundingClientRect(), y = E(g), v = d.left + (g.clientLeft + parseFloat(y.paddingLeft)) * w.x, b = d.top + (g.clientTop + parseFloat(y.paddingTop)) * w.y;
      a *= w.x, l *= w.y, u *= w.x, f *= w.y, a += v, l += b, p = A(g), g = p.frameElement;
    }
  }
  return nt({
    width: u,
    height: f,
    x: a,
    y: l
  });
}
function Ue(t) {
  let {
    elements: e,
    rect: n,
    offsetParent: o,
    strategy: i
  } = t;
  const r = i === "fixed", s = O(o), c = e ? lt(e.floating) : !1;
  if (o === s || c && r)
    return n;
  let a = {
    scrollLeft: 0,
    scrollTop: 0
  }, l = C(1);
  const u = C(0), f = L(o);
  if ((f || !f && !r) && ((U(o) !== "body" || K(s)) && (a = at(o)), L(o))) {
    const m = F(o);
    l = $(o), u.x = m.x + o.clientLeft, u.y = m.y + o.clientTop;
  }
  return {
    width: n.width * l.x,
    height: n.height * l.y,
    x: n.x * l.x - a.scrollLeft * l.x + u.x,
    y: n.y * l.y - a.scrollTop * l.y + u.y
  };
}
function _e(t) {
  return Array.from(t.getClientRects());
}
function Zt(t) {
  return F(O(t)).left + at(t).scrollLeft;
}
function Be(t) {
  const e = O(t), n = at(t), o = t.ownerDocument.body, i = W(e.scrollWidth, e.clientWidth, o.scrollWidth, o.clientWidth), r = W(e.scrollHeight, e.clientHeight, o.scrollHeight, o.clientHeight);
  let s = -n.scrollLeft + Zt(t);
  const c = -n.scrollTop;
  return E(o).direction === "rtl" && (s += W(e.clientWidth, o.clientWidth) - i), {
    width: i,
    height: r,
    x: s,
    y: c
  };
}
function Ie(t, e) {
  const n = A(t), o = O(t), i = n.visualViewport;
  let r = o.clientWidth, s = o.clientHeight, c = 0, a = 0;
  if (i) {
    r = i.width, s = i.height;
    const l = gt();
    (!l || l && e === "fixed") && (c = i.offsetLeft, a = i.offsetTop);
  }
  return {
    width: r,
    height: s,
    x: c,
    y: a
  };
}
function je(t, e) {
  const n = F(t, !0, e === "fixed"), o = n.top + t.clientTop, i = n.left + t.clientLeft, r = L(t) ? $(t) : C(1), s = t.clientWidth * r.x, c = t.clientHeight * r.y, a = i * r.x, l = o * r.y;
  return {
    width: s,
    height: c,
    x: a,
    y: l
  };
}
function Lt(t, e, n) {
  let o;
  if (e === "viewport")
    o = Ie(t, n);
  else if (e === "document")
    o = Be(O(t));
  else if (R(e))
    o = je(e, n);
  else {
    const i = Qt(t);
    o = {
      ...e,
      x: e.x - i.x,
      y: e.y - i.y
    };
  }
  return nt(o);
}
function te(t, e) {
  const n = P(t);
  return n === e || !R(n) || H(n) ? !1 : E(n).position === "fixed" || te(n, e);
}
function Ye(t, e) {
  const n = e.get(t);
  if (n)
    return n;
  let o = j(t, [], !1).filter((c) => R(c) && U(c) !== "body"), i = null;
  const r = E(t).position === "fixed";
  let s = r ? P(t) : t;
  for (; R(s) && !H(s); ) {
    const c = E(s), a = pt(s);
    !a && c.position === "fixed" && (i = null), (r ? !a && !i : !a && c.position === "static" && !!i && ["absolute", "fixed"].includes(i.position) || K(s) && !a && te(t, s)) ? o = o.filter((u) => u !== s) : i = c, s = P(s);
  }
  return e.set(t, o), o;
}
function Xe(t) {
  let {
    element: e,
    boundary: n,
    rootBoundary: o,
    strategy: i
  } = t;
  const s = [...n === "clippingAncestors" ? lt(e) ? [] : Ye(e, this._c) : [].concat(n), o], c = s[0], a = s.reduce((l, u) => {
    const f = Lt(e, u, i);
    return l.top = W(f.top, l.top), l.right = Z(f.right, l.right), l.bottom = Z(f.bottom, l.bottom), l.left = W(f.left, l.left), l;
  }, Lt(e, c, i));
  return {
    width: a.right - a.left,
    height: a.bottom - a.top,
    x: a.left,
    y: a.top
  };
}
function qe(t) {
  const {
    width: e,
    height: n
  } = Gt(t);
  return {
    width: e,
    height: n
  };
}
function Ke(t, e, n) {
  const o = L(e), i = O(e), r = n === "fixed", s = F(t, !0, r, e);
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const a = C(0);
  if (o || !o && !r)
    if ((U(e) !== "body" || K(i)) && (c = at(e)), o) {
      const f = F(e, !0, r, e);
      a.x = f.x + e.clientLeft, a.y = f.y + e.clientTop;
    } else i && (a.x = Zt(i));
  const l = s.left + c.scrollLeft - a.x, u = s.top + c.scrollTop - a.y;
  return {
    x: l,
    y: u,
    width: s.width,
    height: s.height
  };
}
function ft(t) {
  return E(t).position === "static";
}
function Ot(t, e) {
  return !L(t) || E(t).position === "fixed" ? null : e ? e(t) : t.offsetParent;
}
function ee(t, e) {
  const n = A(t);
  if (lt(t))
    return n;
  if (!L(t)) {
    let i = P(t);
    for (; i && !H(i); ) {
      if (R(i) && !ft(i))
        return i;
      i = P(i);
    }
    return n;
  }
  let o = Ot(t, e);
  for (; o && Ve(o) && ft(o); )
    o = Ot(o, e);
  return o && H(o) && ft(o) && !pt(o) ? n : o || $e(t) || n;
}
const Je = async function(t) {
  const e = this.getOffsetParent || ee, n = this.getDimensions, o = await n(t.floating);
  return {
    reference: Ke(t.reference, await e(t.floating), t.strategy),
    floating: {
      x: 0,
      y: 0,
      width: o.width,
      height: o.height
    }
  };
};
function Ge(t) {
  return E(t).direction === "rtl";
}
const Qe = {
  convertOffsetParentRelativeRectToViewportRelativeRect: Ue,
  getDocumentElement: O,
  getClippingRect: Xe,
  getOffsetParent: ee,
  getElementRects: Je,
  getClientRects: _e,
  getDimensions: qe,
  getScale: $,
  isElement: R,
  isRTL: Ge
};
function Ze(t, e) {
  let n = null, o;
  const i = O(t);
  function r() {
    var c;
    clearTimeout(o), (c = n) == null || c.disconnect(), n = null;
  }
  function s(c, a) {
    c === void 0 && (c = !1), a === void 0 && (a = 1), r();
    const {
      left: l,
      top: u,
      width: f,
      height: m
    } = t.getBoundingClientRect();
    if (c || e(), !f || !m)
      return;
    const h = G(u), p = G(i.clientWidth - (l + f)), g = G(i.clientHeight - (u + m)), w = G(l), y = {
      rootMargin: -h + "px " + -p + "px " + -g + "px " + -w + "px",
      threshold: W(0, Z(1, a)) || 1
    };
    let v = !0;
    function b(x) {
      const N = x[0].intersectionRatio;
      if (N !== a) {
        if (!v)
          return s();
        N ? s(!1, N) : o = setTimeout(() => {
          s(!1, 1e-7);
        }, 1e3);
      }
      v = !1;
    }
    try {
      n = new IntersectionObserver(b, {
        ...y,
        // Handle <iframe>s
        root: i.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(b, y);
    }
    n.observe(t);
  }
  return s(!0), r;
}
function tn(t, e, n, o) {
  o === void 0 && (o = {});
  const {
    ancestorScroll: i = !0,
    ancestorResize: r = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: c = typeof IntersectionObserver == "function",
    animationFrame: a = !1
  } = o, l = wt(t), u = i || r ? [...l ? j(l) : [], ...j(e)] : [];
  u.forEach((d) => {
    i && d.addEventListener("scroll", n, {
      passive: !0
    }), r && d.addEventListener("resize", n);
  });
  const f = l && c ? Ze(l, n) : null;
  let m = -1, h = null;
  s && (h = new ResizeObserver((d) => {
    let [y] = d;
    y && y.target === l && h && (h.unobserve(e), cancelAnimationFrame(m), m = requestAnimationFrame(() => {
      var v;
      (v = h) == null || v.observe(e);
    })), n();
  }), l && !a && h.observe(l), h.observe(e));
  let p, g = a ? F(t) : null;
  a && w();
  function w() {
    const d = F(t);
    g && (d.x !== g.x || d.y !== g.y || d.width !== g.width || d.height !== g.height) && n(), g = d, p = requestAnimationFrame(w);
  }
  return n(), () => {
    var d;
    u.forEach((y) => {
      i && y.removeEventListener("scroll", n), r && y.removeEventListener("resize", n);
    }), f == null || f(), (d = h) == null || d.disconnect(), h = null, a && cancelAnimationFrame(p);
  };
}
const en = Fe, nn = Ne, St = We, on = (t, e, n) => {
  const o = /* @__PURE__ */ new Map(), i = {
    platform: Qe,
    ...n
  }, r = {
    ...i.platform,
    _c: o
  };
  return De(t, e, {
    ...i,
    platform: r
  });
}, bn = ({
  content: t,
  open: e,
  placement: n,
  offset: { mainAxis: o, crossAxis: i } = { mainAxis: 0, crossAxis: 0 }
}) => (r) => {
  const s = r.element, c = rt.toSignal(e);
  return Ut(
    c,
    zt(
      "body",
      Ft.div(
        Ht((a) => {
          const l = a;
          return l.style.position = "absolute", tn(s, l, () => {
            on(s, l, {
              placement: n,
              strategy: "absolute",
              middleware: [
                St(),
                en({ mainAxis: o, crossAxis: i }),
                nn(),
                St()
              ]
            }).then(({ x: u, y: f }) => {
              l.style.top = `${f}px`, l.style.left = `${u}px`;
            });
          });
        }),
        t()
      )
    )
  )(r);
}, rn = (t, e) => {
  if (typeof e == "function")
    return rn(t, { success: e });
  const n = e.failure ?? ((i) => X(
    q(i.on(console.error)),
    i.map((r) => `Error: ${r}`)
  )), o = e.success;
  return $t(rt.toSignal(t), {
    Success: (i) => o(i.$.value),
    Failure: (i) => n(i.$.error)
  });
}, An = () => Nt.focus((t) => {
  var e;
  return (e = t.target) == null ? void 0 : e.select();
}), En = (t) => (e) => {
  const n = e.element, o = k({ width: n.clientWidth, height: n.clientHeight }), i = it(t(o))(e), r = () => {
    o.set({ width: n.clientWidth, height: n.clientHeight });
  };
  let s;
  return typeof ResizeObserver == "function" && (s = new ResizeObserver(r), s.observe(n)), (c) => {
    s == null || s.disconnect(), i(c);
  };
}, Rn = (t) => (e) => {
  const n = k({
    width: (window == null ? void 0 : window.innerWidth) ?? 0,
    height: (window == null ? void 0 : window.innerHeight) ?? 0
  }), o = it(t(n))(e), i = () => {
    n.set({
      width: (window == null ? void 0 : window.innerWidth) ?? 0,
      height: (window == null ? void 0 : window.innerHeight) ?? 0
    });
  };
  return window == null || window.addEventListener("resize", i), (r) => {
    window == null || window.removeEventListener("resize", i), o(r);
  };
}, sn = (t, e) => {
  const n = e.split("/").filter((i) => i !== ""), o = {};
  for (let i = 0; i < t.length; i++) {
    const r = t[i], s = n[i];
    if (!s && r.type !== "catch-all")
      return null;
    if (r.type === "literal") {
      if (r.value !== s)
        return null;
    } else if (r.type === "param")
      o[r.name] = s;
    else if (r.type === "catch-all")
      return { params: o, path: e };
  }
  return n.length !== t.length ? null : { params: o, path: e };
}, cn = (t) => t.split("/").map((e) => e.startsWith(":") ? { type: "param", name: e.slice(1) } : e === "*" ? { type: "catch-all" } : { type: "literal", value: e }).filter((e) => e.type !== "literal" || e.value !== ""), ln = (t) => {
  const e = t.map((n) => {
    const o = cn(n);
    return { route: n, segments: o };
  });
  return function(o) {
    for (const { segments: i, route: r } of e) {
      const s = sn(i, o);
      if (s)
        return { ...s, route: r };
    }
    return null;
  };
}, Ln = (t) => {
  const e = ln(Object.keys(t));
  return Bt((n) => {
    const o = n.map((i) => {
      const r = e(i.pathname);
      if (r == null)
        throw console.error("No route found for", i), new Error("No route found");
      return {
        params: r.params,
        route: r.route,
        path: r.path,
        search: i.search,
        hash: i.hash
      };
    });
    return se(
      o.map((i) => [i.route, i]),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      t
    );
  });
}, ot = 60 * 1e3, mt = 60 * ot, Y = 24 * mt, Tt = 7 * Y, kt = 30 * Y, an = 365 * Y, un = [
  {
    max: ot * 90,
    value: ot,
    name: "minute",
    past: { singular: "a minute ago", plural: "{} minutes ago" },
    future: { singular: "in a minute", plural: "in {} minutes" }
  },
  {
    max: mt * 36,
    value: mt,
    name: "hour",
    past: { singular: "an hour ago", plural: "{} hours ago" },
    future: { singular: "in an hour", plural: "in {} hours" }
  },
  {
    max: Y * 10,
    value: Y,
    name: "day",
    past: { singular: "yesterday", plural: "{} days ago" },
    future: { singular: "tomorrow", plural: "in {} days" }
  },
  {
    max: Tt * 6,
    value: Tt,
    name: "week",
    past: { singular: "last week", plural: "{} weeks ago" },
    future: { singular: "in a week", plural: "in {} weeks" }
  },
  {
    max: kt * 18,
    value: kt,
    name: "month",
    past: { singular: "last month", plural: "{} months ago" },
    future: { singular: "in a month", plural: "in {} months" }
  },
  {
    max: 1 / 0,
    value: an,
    name: "year",
    past: { singular: "last year", plural: "{} years ago" },
    future: { singular: "in a year", plural: "in {} years" }
  }
];
function Ct(t, e, n, o) {
  const i = Math.round(t / e);
  return i <= 1 ? n : o.replace(
    "{}",
    i.toLocaleString(void 0, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })
  );
}
function fn(t) {
  const e = Math.abs(t);
  if (e < ot)
    return t < 0 ? "just now" : "in a moment";
  for (const n of un)
    if (e < n.max)
      return t < 0 ? Ct(e, n.value, n.past.singular, n.past.plural) : Ct(e, n.value, n.future.singular, n.future.plural);
  throw new Error("unreachable");
}
const On = (t, e) => {
  const n = e != null ? Mt.is(e) ? e.map((r) => r) : k(e) : k(/* @__PURE__ */ new Date()), o = ce(
    t,
    n
  )((r, s) => {
    const c = s.getTime() - r.getTime();
    return fn(c);
  }), i = le.is(n) ? setInterval(() => n.set(/* @__PURE__ */ new Date()), 5e3) : void 0;
  return o.onDispose(() => {
    i != null && clearInterval(i), n.dispose();
  }), o;
};
export {
  ve as Anchor,
  xe as AsyncResultView,
  gn as AutoFocus,
  wn as AutoSelect,
  En as ElementSize,
  vn as HTMLTitle,
  yn as HiddenWhenEmpty,
  Ee as InViewport,
  _t as LocationProviderMarker,
  bn as PopOver,
  mn as ProvideAppearance,
  dn as ProvideLocation,
  rn as ResultView,
  Ln as Router,
  An as SelectOnFocus,
  pn as UseAppearance,
  Bt as UseLocation,
  xn as WhenInViewport,
  Rn as WindowSize,
  ue as _checkExtensionCondition,
  ae as _getExtension,
  de as _makeLocation,
  ye as _makeLocationProp,
  ln as _makeRouteMatcher,
  cn as _parseRouteSegments,
  It as appearanceMarker,
  me as areLocationsEqual,
  he as handleAnchorClick,
  pe as locationFromURL,
  On as makeRelativeTime,
  sn as matchesRoute,
  ge as setLocationFromUrl,
  fn as timeDiffToString,
  we as urlFromLocation
};

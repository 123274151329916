import { anyElement as l, allElements as b, generateArray as S, createFilledArray as O } from "./array.js";
import { MissingImplementationError as A } from "./error.js";
import { mapRegExp as h } from "./regexp.js";
const u = (t, n, e) => t.split(n).join(e), X = (t, n) => {
  const e = t.indexOf(n);
  return e < 0 ? "" : t.substring(e + n.length);
}, Y = (t, n) => {
  const e = t.lastIndexOf(n);
  return e < 0 ? "" : t.substring(e + n.length);
}, v = (t, n) => {
  const e = t.indexOf(n);
  return e < 0 ? "" : t.substring(0, e);
}, tt = (t, n) => {
  const e = t.lastIndexOf(n);
  return e < 0 ? "" : t.substring(0, e);
}, p = (t) => t.substring(0, 1).toUpperCase() + t.substring(1), d = (t) => t.toUpperCase(), nt = (t, n = !1) => n ? h(p(t), F, d) : h(p(t), D, d), et = (t) => t.replace(K, `
`), rt = (t, n) => t == null && n == null ? 0 : t == null ? -1 : n == null ? 1 : W(t.toLowerCase(), n.toLowerCase()), L = (t, n) => t.substring(0, t.length - n.length) === n, st = (t, n) => t.substring(0, t.length - n.length).toLowerCase() === n.toLowerCase(), x = (t, n) => t.substring(0, n.length) === n, it = (t, n) => t.substring(0, n.length).toLowerCase() === n.toLowerCase(), ot = (t, n) => B(
  t.toLowerCase(),
  n.map((e) => e.toLowerCase())
), ct = (t, n) => M(
  t.toLowerCase(),
  n.map((e) => e.toLowerCase())
), ut = (t) => t.trim().replace(m, " "), W = (t, n) => t < n ? -1 : t > n ? 1 : 0, w = (t, n) => t.toLowerCase().includes(n.toLowerCase()), a = (t, n) => t.includes(n), gt = (t, n) => t.split(n).length - 1, lt = (t, n) => l(n, (e) => w(t, e)), at = (t, n) => l(n, (e) => a(t, e)), ft = (t, n) => b(n, (e) => w(t, e)), ht = (t, n) => b(n, (e) => a(t, e)), pt = (t) => t.replace("_", "-"), dt = (t, n) => {
  if (t === n) return -1;
  const e = Math.min(t.length, n.length);
  for (let r = 0; r < e; r++)
    if (t.substring(r, r + 1) !== n.substring(r, r + 1)) return r;
  return e;
}, y = (t, n = 20, e = "…") => {
  const r = t.length, s = e.length;
  return r > n ? n < s ? e.slice(s - n, n) : t.slice(0, n - s) + e : t;
}, Ct = (t, n = 20, e = "…") => {
  const r = t.length, s = e.length;
  if (r > n) {
    if (n <= s)
      return y(t, n, e);
    const c = Math.ceil((n - s) / 2), g = Math.floor((n - s) / 2);
    return t.slice(0, c) + e + t.slice(r - g);
  } else return t;
}, B = (t, n) => l(n, (e) => L(t, e)), bt = (t, n) => f(t).filter(n).join(""), St = (t, n) => _(t).filter(n).map((r) => String.fromCharCode(r)).join(""), At = (t, n = 2166136261) => {
  let e = n;
  for (let r = 0, s = t.length; r < s; r++)
    e ^= t.charCodeAt(r), e += (e << 1) + (e << 4) + (e << 7) + (e << 8) + (e << 24);
  return e >>> 0;
}, Lt = (t) => t != null && t.length > 0, wt = (t) => u(k(t), "_", " "), It = (t) => t.length > 0 && !R.test(t), mt = (t) => G.test(t), Et = (t) => !P.test(t), Ot = (t) => t.toLowerCase() === t, xt = (t) => t.toUpperCase() === t, Wt = (t, n) => t != null && t !== "" ? t : n, yt = (t) => Q.test(t), Bt = (t) => t == null || t === "", zt = (t) => t.substring(0, 1).toLowerCase() + t.substring(1), z = (t, n = 1) => t.substring(
  Math.floor((t.length - n + 1) * Math.random()),
  n
), T = (t, n) => S(n, () => z(t)).join(""), Tt = (t) => T(Z, t), jt = (t, n) => f(n).map(t), Nt = (t, n) => u(t, n, ""), Mt = (t, n) => L(t, n) ? t.substring(0, t.length - n.length) : t, _t = (t, n, e) => t.substring(0, n) + t.substring(n + e), Ut = (t, n) => x(t, n) ? t.substring(n.length) : t, $t = (t, n) => {
  const e = t.indexOf(n);
  return e < 0 ? t : t.substring(0, e) + t.substring(e + n.length);
}, I = (t, n) => O(n, t).join(""), kt = (t) => {
  const n = f(t);
  return n.reverse(), n.join("");
}, j = (t, n = "'") => n === "'" ? t.includes("'") ? t.includes('"') ? "'" + u(t, "'", "\\'") + "'" : '"' + t + '"' : "'" + t + "'" : t.includes('"') ? t.includes("'") ? '"' + u(t, '"', '\\"') + '"' : "'" + t + "'" : '"' + t + '"', N = (t, n = "'") => n + u(t, n, "\\" + n) + n, Ht = (t, n = "'") => t.indexOf(`
`) >= 0 ? N(t, "`") : j(t, n), Zt = (t, n) => {
  const e = t.indexOf(n);
  return e < 0 ? [t] : [t.substring(0, e), t.substring(e + n.length)];
}, M = (t, n) => l(n, (e) => t.startsWith(e)), Dt = (t, n, e = n) => `${n}${t}${e}`, f = (t) => t.split(""), _ = (t) => S(t.length, (n) => t.charCodeAt(n)), Pt = (t, n) => {
  const e = [];
  for (; t.length > 0; )
    e.push(t.substring(0, n)), t = t.substring(n, t.length - n);
  return e;
}, Rt = (t) => t.split(E), Ft = (t, n) => $(U(t, n), n), U = (t, n) => {
  let e = 0;
  for (let r = 0; r < t.length && a(n, t.charAt(r)); r++)
    e++;
  return t.substring(e);
}, $ = (t, n) => {
  const e = t.length;
  let r = e, s;
  for (let c = 0; c < e && (s = e - c - 1, a(n, t.charAt(s))); c++)
    r = s;
  return t.substring(0, r);
}, k = (t) => (t = t.replace(/::/g, "/"), t = t.replace(/([A-Z]+)([A-Z][a-z])/g, "$1_$2"), t = t.replace(/([a-z\d])([A-Z])/g, "$1_$2"), t = t.replace(/-/g, "_"), t.toLowerCase()), Gt = (t) => t.substring(0, 1).toUpperCase() + t.substring(1), Qt = (t, n = 78, e = "", r = `
`) => t.split(E).map(
  (s) => H(s.replace(m, " ").trim(), n, e, r)
).join(r), C = (t, n) => {
  if (n < 0 || n >= t.length) return !1;
  const e = t.charCodeAt(n);
  return e === 9 || e === 10 || e === 11 || e === 12 || e === 13 || e === 32;
}, Kt = (t) => {
  if (typeof Buffer < "u")
    return Buffer.from(t).toString("base64");
  if (typeof btoa < "u")
    return btoa(t);
  throw new A(
    "No implementation found for base64 encoding"
  );
}, qt = (t) => {
  if (typeof Buffer < "u")
    return Buffer.from(t, "base64").toString("utf8");
  if (typeof atob < "u")
    return atob(t);
  throw new A(
    "No implementation found for base64 decoding"
  );
}, H = (t, n, e, r) => {
  const s = [], c = t.length, g = e.length;
  let o = 0;
  for (n -= g; ; ) {
    if (o + n >= c - g) {
      s.push(t.substring(o));
      break;
    }
    let i = 0;
    for (; !C(t, o + n - i) && i < n; ) i++;
    if (i === n) {
      for (i = 0; !C(t, o + n + i) && o + n + i < c; ) i++;
      s.push(t.substring(o, o + n + i)), o += n + i + 1;
    } else
      s.push(t.substring(o, o + n - i)), o += n - i + 1;
  }
  return e + s.join(r + e);
}, Jt = (t, n, e) => {
  const r = e - t.length;
  return r > 0 ? I(n, r) + t : t;
}, Vt = (t, n, e) => {
  const r = e - t.length;
  return r > 0 ? t + I(n, r) : t;
}, Xt = (t, n) => {
  const e = t.lastIndexOf(n);
  return e >= 0 ? [t.substring(0, e), t.substring(e + n.length)] : [t];
}, Yt = (t, n) => {
  const e = t.indexOf(n);
  return e >= 0 ? [t.substring(0, e), t.substring(e + n.length)] : [t];
}, Z = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/", D = /[^a-zA-Z]([a-z])/g, P = /[^\t\n\r ]/, R = /[^a-zA-Z]/, F = /[ \t\r\n][a-z]/g, G = /^[a-z0-9]+$/i, Q = /^[0-9]+$/, m = /[ \t\r\n]+/g, E = /\r\n|\n\r|\n|\r/g, K = /\r\n|\n\r|\r/g;
export {
  et as canonicalizeNewlines,
  p as capitalize,
  nt as capitalizeWords,
  Pt as chunkString,
  ut as collapseText,
  rt as compareCaseInsensitive,
  W as compareStrings,
  ht as containsAllText,
  ft as containsAllTextCaseInsensitive,
  at as containsAnyText,
  lt as containsAnyTextCaseInsensitive,
  gt as countStringOccurrences,
  pt as dasherize,
  qt as decodeBase64,
  $t as deleteFirstFromString,
  Mt as deleteStringAfter,
  Ut as deleteStringBefore,
  Nt as deleteSubstring,
  y as ellipsis,
  Ct as ellipsisMiddle,
  Kt as encodeBase64,
  St as filterCharcodes,
  bt as filterChars,
  wt as humanize,
  Wt as ifEmptyString,
  It as isAlpha,
  mt as isAlphaNum,
  Et as isBreakingWhitespace,
  yt as isDigitsOnly,
  Bt as isEmptyString,
  Ot as isLowerCase,
  C as isSpaceAt,
  xt as isUpperCase,
  Ht as jsQuote,
  zt as lowerCaseFirst,
  Jt as lpad,
  jt as mapChars,
  N as quote,
  z as randomString,
  T as randomStringSequence,
  Tt as randomStringSequenceBase64,
  I as repeatString,
  u as replaceAll,
  kt as reverseString,
  Vt as rpad,
  j as smartQuote,
  Yt as splitStringOnFirst,
  Xt as splitStringOnLast,
  Zt as splitStringOnce,
  a as stringContains,
  L as stringEndsWith,
  B as stringEndsWithAny,
  Lt as stringHasContent,
  At as stringHashCode,
  x as stringStartsWith,
  M as stringStartsWithAny,
  _ as stringToCharcodes,
  f as stringToChars,
  dt as stringsDifferAtIndex,
  X as substringAfter,
  Y as substringAfterLast,
  v as substringBefore,
  tt as substringBeforeLast,
  Dt as surroundString,
  w as textContainsCaseInsensitive,
  ot as textEndsWithAnyCaseInsensitive,
  st as textEndsWithCaseInsensitive,
  ct as textStartsWithAnyCaseInsensitive,
  it as textStartsWithCaseInsensitive,
  Rt as textToLines,
  Ft as trimChars,
  U as trimCharsLeft,
  $ as trimCharsRight,
  _t as trimStringSlice,
  k as underscore,
  Gt as upperCaseFirst,
  Qt as wrapColumns,
  H as wrapLine
};

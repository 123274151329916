var Me = Object.defineProperty;
var x = (t) => {
  throw TypeError(t);
};
var $e = (t, e, r) => e in t ? Me(t, e, { enumerable: !0, configurable: !0, writable: !0, value: r }) : t[e] = r;
var u = (t, e, r) => $e(t, typeof e != "symbol" ? e + "" : e, r), ee = (t, e, r) => e.has(t) || x("Cannot " + r);
var D = (t, e, r) => (ee(t, e, "read from private field"), r ? r.call(t) : e.get(t)), te = (t, e, r) => e.has(t) ? x("Cannot add the same private member more than once") : e instanceof WeakSet ? e.add(t) : e.set(t, r), J = (t, e, r, s) => (ee(t, e, "write to private field"), s ? s.call(t, r) : e.set(t, r), r);
const Fe = (t, e, r) => t + (e - t) * r;
const Ve = (t, e, r) => {
  const s = Math.max(t.length, e.length);
  let n = "";
  for (let o = 0; o < s; o++) {
    let i = t.charCodeAt(o);
    isNaN(i) && (i = 97);
    let l = e.charCodeAt(o);
    isNaN(l) && (l = 97), n += String.fromCharCode(i + (l - i) * r);
  }
  return n;
}, qe = (t, e, r) => new Date(t.getTime() + (e.getTime() - t.getTime()) * r), Ie = (t, e) => e, Be = (t) => typeof t == "number" ? Fe : typeof t == "string" ? Ve : t instanceof Date ? qe : Ie;
var v;
class T {
  /**
   * Creates a new instance of `ElementPosition`.
   * @param index - The index of the element.
   * @param total - The total number of elements in the collection.
   */
  constructor(e, r) {
    /**
     * The counter of the element starting from 1.
     */
    u(this, "counter");
    /**
     * Checks if the element is the first element in the collection.
     * @returns `true` if the element is the first element, `false` otherwise.
     */
    u(this, "isFirst");
    /**
     * Checks if the counter of the element is even.
     * @returns `true` if the counter is even, `false` otherwise.
     */
    u(this, "isEven");
    /**
     * Checks if the counter of the element is odd.
     * @returns `true` if the counter is odd, `false` otherwise.
     */
    u(this, "isOdd");
    te(this, v);
    u(this, "dispose", () => {
      D(this, v) != null && (D(this, v).dispose(), J(this, v, void 0));
    });
    this.index = e, this.total = r, this.counter = e + 1, this.isFirst = e === 0, this.isEven = e % 2 === 1, this.isOdd = e % 2 === 0;
  }
  /**
   * Checks if the element is the last element in the collection.
   * @returns `true` if the element is the last element, `false` otherwise.
   */
  get isLast() {
    return D(this, v) == null && J(this, v, this.total.map((e) => this.counter === e)), D(this, v);
  }
}
v = new WeakMap();
const C = class C {
  /**
   * Represents a signal with a value of type T.
   *
   * @param value - The initial value of the signal.
   * @param equals - A function that determines whether two values of type T are equal.
   * @public
   */
  constructor(e, r) {
    /**
     * @internal
     */
    u(this, "$__signal__", !0);
    /**
     * @internal
     */
    u(this, "_value");
    /**
     * @internal
     */
    u(this, "_derivatives", []);
    /**
     * @internal
     */
    u(this, "_onValueListeners", []);
    /**
     * @internal
     */
    u(this, "_onDisposeListeners", []);
    /**
     * Gets the current value of the signal.
     * @returns The current value of the signal.
     */
    u(this, "get", () => this._value);
    /**
     * Checks if the signal has any registered listeners.
     * @returns `true` if the signal has listeners, `false` otherwise.
     */
    u(this, "hasListeners", () => this._onValueListeners.length > 0);
    /**
     * Registers a listener function to be called whenever the value of the signal changes.
     * The listener function will be immediately called with the current value of the signal.
     * Returns a function that can be called to unregister the listener.
     *
     * @param listener - The listener function to be called when the value of the signal changes.
     */
    u(this, "on", (e) => (e(this.get()), this._onValueListeners.push(e), () => {
      this._onValueListeners.splice(this._onValueListeners.indexOf(e), 1);
    }));
    /**
     * @internal
     */
    u(this, "_setAndNotify", (e, r) => {
      const s = this.equals(this._value, e);
      s || (this._value = e), (r || !s) && this._onValueListeners.forEach((n) => n(e));
    });
    /**
     * @internal
     */
    u(this, "_disposed", !1);
    /**
     * Checks whether the signal is disposed.
     * @returns True if the signal is disposed, false otherwise.
     */
    u(this, "isDisposed", () => this._disposed);
    /**
     * Adds a listener function to be called when the object is disposed.
     * @param listener - The listener function to be called when the object is disposed.
     * @returns A function that can be called to remove the listener.
     */
    u(this, "onDispose", (e) => {
      this._onDisposeListeners.push(e);
    });
    /**
     * Disposes the signal, releasing any resources associated with it.
     */
    u(this, "dispose", () => {
      this._disposed || (this._disposed = !0, this._onDisposeListeners.forEach((e) => e()), this._onDisposeListeners.length = 0, this._derivatives.length = 0);
    });
    /**
     * Returns a new Computed instance that applies the given mapping function to the value of this Signal.
     * The mapping function is called whenever the value of this Signal changes.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The mapping function to apply to the value of this Signal.
     * @param equals - Optional equality function to determine if two mapped values are equal.
     * @returns - A new Computed instance with the mapped value.
     */
    u(this, "map", (e, r = (s, n) => s === n) => {
      const s = new E(() => {
        try {
          return e(this.get());
        } catch (n) {
          throw console.error("Error in Signal.map:", n), n;
        }
      }, r);
      return this.setDerivative(s), s;
    });
    /**
     * Returns a new Signal that applies the given function to the value of the current Signal,
     * and then flattens the resulting Signal.
     *
     * @typeParam O - The type of the value emitted by the resulting Signal.
     * @param fn - The function to apply to the value of the current Signal.
     * @param equals - A function that determines whether two values of type O are equal.
     *               Defaults to a strict equality check (===).
     * @returns A new Signal that emits the values of the resulting Signal.
     */
    u(this, "flatMap", (e, r = (s, n) => s === n) => {
      const s = new E(() => {
        try {
          return e(this.get()).get();
        } catch (n) {
          throw console.error("Error in Signal.flatMap:", n), n;
        }
      }, r);
      return this.setDerivative(s), s;
    });
    /**
     * Invokes a callback function with the current value of the signal, without modifying the signal.
     *
     * @param fn - The callback function to be invoked with the current value of the signal.
     * @returns A new signal that emits the same value as the original signal and invokes the callback function.
     */
    u(this, "tap", (e) => this.map((r) => (e(r), r)));
    /**
     * Returns a new Signal that emits the value at the specified key of the current value.
     *
     * @param key - The key of the value to retrieve.
     * @returns A new Signal that emits the value at the specified key.
     */
    u(this, "at", (e) => this.map((r) => r[e]));
    /**
     * @internal
     */
    u(this, "_$");
    u(this, "filter", (e, r) => {
      let s = r ?? this.get();
      const n = new E(() => {
        try {
          const o = this.get();
          return s = e(o) ? o : s;
        } catch (o) {
          throw console.error("Error in Signal.filter:", o), o;
        }
      }, this.equals);
      return this.setDerivative(n), n;
    });
    /**
     * Returns a new Computed object that applies the provided mapping function to the value of this Signal,
     * and filters out values that are `undefined` or `null`.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The mapping function to apply to the value of this Signal.
     * @param startValue - The initial value for the Computed object.
     * @param equals - Optional equality function to determine if two values are equal.
     * @returns - A new Computed object with the mapped and filtered values.
     */
    u(this, "filterMap", (e, r, s = (n, o) => n === o) => {
      let n = r;
      const o = new E(() => {
        try {
          const i = this.get(), l = e(i);
          return n = l ?? n;
        } catch (i) {
          throw console.error("Error in Signal.filterMap:", i), i;
        }
      }, s);
      return this.setDerivative(o), o;
    });
    /**
     * Maps the values emitted by the signal to a new value asynchronously using the provided function.
     * If the function throws an error, it will be caught and logged.
     * If a recovery function is provided, it will be called with the error and its return value will be used as the mapped value.
     * If no recovery function is provided, the error will be logged as an unhandled promise rejection.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The function to map the values emitted by the signal. The second argument to this function allows to cancel the previously running mapping function if it has not completed by the time a new value is emitted.
     * @param alt - The alternate value to use if the signal is disposed or the mapping function throws an error.
     * @param recover - The recovery function to handle errors thrown by the mapping function.
     * @param equals - The equality function to compare the mapped values for equality.
     * @returns A property that holds the mapped value and can be observed for changes.
     */
    u(this, "mapAsync", (e, r, s, n = (o, i) => o === i) => {
      const o = V(r, n);
      let i = 0, l = new AbortController();
      return o.onDispose(
        this.on(async (a) => {
          const f = ++i;
          l.abort(), l = new AbortController();
          try {
            const g = await e(a, { abortSignal: l.signal });
            f === i && o.set(g);
          } catch (g) {
            if (f === i)
              if (s != null)
                o.set(s(g));
              else
                throw g;
          }
        })
      ), o;
    });
    /**
     * Maps the values of the signal using the provided function `fn`, and returns a new signal
     * containing the mapped values. If the mapped value is `undefined` or `null`, it is replaced
     * with the provided `alt` value.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The function used to map the values of the signal.
     * @param alt - The alternative value to use when the mapped value is `undefined` or `null`.
     * @returns A new signal containing the mapped values.
     */
    u(this, "mapMaybe", (e, r) => this.map((s) => e(s) ?? r));
    /**
     * Feeds a property into the signal and sets up disposal behavior.
     * @param prop - The property to feed into the signal.
     * @param autoDisposeProp - Determines whether the property should be automatically disposed when the signal is disposed.
     * @returns The input property.
     */
    u(this, "feedProp", (e, r = !1) => {
      const s = this.on(e.set);
      return e.onDispose(s), r ? this.onDispose(e.dispose) : this.onDispose(s), e;
    });
    /**
     * Derives a new property from the current signal.
     * @param autoDisposeProp - Determines whether the derived property should be automatically disposed.
     * @returns The derived property.
     */
    u(this, "deriveProp", (e = !0) => this.feedProp(V(this.get()), e));
    /**
     * Returns a signal that emits the count of values received so far.
     * @returns A signal that emits the count of values received so far.
     */
    u(this, "count", () => {
      let e = 0;
      return this.map(() => ++e);
    });
    /**
     * Adds a computed value as a derivative of the signal.
     * When the computed value is disposed, it is automatically removed from the derivatives list.
     * Additionally, when the computed value is disposed, it sets the signal as dirty.
     * @param computed - The computed value to add as a derivative.
     */
    u(this, "setDerivative", (e) => {
      this._derivatives.push(e), e.onDispose(() => {
        this._derivatives.splice(
          this._derivatives.indexOf(e),
          1
        );
      }), e.onDispose(this.on(e.setDirty)), this.onDispose(e.dispose);
    });
    this.equals = r, this._value = e;
  }
  /**
   * Gets the value of the signal.
   * @returns The current value of the signal.
   */
  get value() {
    return this._value;
  }
  /**
   * Represents a collection of signals mapping to each key/field in the wrapped value.
   * @typeParam T - The type of the signals.
   */
  get $() {
    return this._$ !== void 0 ? this._$ : this._$ = new Proxy(this, {
      get: (e, r) => this.at(r)
    });
  }
};
/**
 * Creates a Signal that holds the result of a Promise.
 *
 * @typeParam O - The type of the value returned by the Promise.
 * @param promise - The Promise to use to feed the Signal.
 * @param init - The initial value of the Signal before the Promise resolves.
 * @param recover - A function to recover from Promise rejection and provide an alternative value for the Signal.
 * @param equals - A function to compare two values of type O for equality. Defaults to strict equality (===).
 * @returns - A Signal that represents the result of the Promise.
 */
u(C, "ofPromise", (e, r, s, n = (o, i) => o === i) => {
  const o = new C(r, n);
  return e.then((i) => o._setAndNotify(i, !1)).catch((i) => {
    s != null ? o._setAndNotify(s(i), !1) : console.error(
      "Unhandled promise rejection in Signal.ofPromise:",
      i
    );
  }), o;
}), /**
 * Checks if a value is a Signal.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a Signal, `false` otherwise.
 */
u(C, "is", (e) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e != null && e.$__signal__ === !0
));
let h = C;
const je = typeof queueMicrotask == "function" ? queueMicrotask : (t) => Promise.resolve().then(t);
class E extends h {
  /**
   * Represents a Signal object.
   * @param _fn - The function that returns the value of the signal.
   * @param equals - The function used to compare two values of type T for equality.
   */
  constructor(r, s) {
    super(void 0, s);
    /**
     * @internal
     */
    u(this, "$__computed__", !0);
    /**
     * @internal
     */
    u(this, "_isDirty", !1);
    /**
     * Marks the signal as dirty, indicating that its value has changed and needs to be recalculated.
     * If the signal is already dirty or disposed, this method does nothing.
     * It also marks all dependent signals as dirty and schedules a notification to update their values.
     */
    u(this, "setDirty", () => {
      this._isDirty || this._disposed || (this._isDirty = !0, this._derivatives.forEach((r) => r.setDirty()), this._scheduleNotify());
    });
    /**
     * @internal
     */
    u(this, "_scheduleCount", 0);
    /**
     * Schedules a notification to be executed asynchronously.
     * If the signal is dirty, it will be updated and notified.
     * @internal
     */
    u(this, "_scheduleNotify", () => {
      const r = ++this._scheduleCount;
      je(() => {
        this._scheduleCount !== r || this._disposed !== !1 || this._isDirty && (this._isDirty = !1, this._setAndNotify(this._fn(), !1));
      });
    });
    /** {@inheritDoc Signal.get} */
    u(this, "get", () => (this._isDirty && (this._isDirty = !1, this._value = this._fn(), this._setAndNotify(this._value, !0)), this._value));
    this._fn = r, this.setDirty();
  }
  /**
   * Checks if a value is an instance of `Computed`.
   *
   * @param value - The value to check.
   * @returns `true` if the value is an instance of `Computed`, `false` otherwise.
   */
  static is(r) {
    return r != null && r.$__computed__ === !0;
  }
  /** {@inheritDoc Signal.value} */
  get value() {
    return this.get();
  }
}
const q = class q extends h {
  constructor() {
    super(...arguments);
    /**
     * @internal
     */
    u(this, "$__prop__", !0);
    /**
     * Changes the value of the property and notifies its listeners.
     *
     * @param value - The new value of the property.
     */
    u(this, "set", (r) => {
      this._setAndNotify(r, !1);
    });
    /**
     * Updates the value of the signal by applying the provided function to the current value.
     * @param fn - The function to apply to the current value.
     */
    u(this, "update", (r) => {
      this._setAndNotify(r(this.get()), !1);
    });
    /**
     * Creates a reducer function that combines the provided reducer function and effects.
     * @param fn - The reducer function that takes the current state and an action, and returns the new state.
     * @param effects - An array of effects to be executed after the state is updated.
     * @returns A dispatch function that can be used to update the state and trigger the effects.
     */
    u(this, "reducer", (r, ...s) => {
      const n = this;
      return function o(i) {
        const l = n.value;
        n.update((a) => r(a, i)), !n.equals(l, n.value) && s.forEach(
          (a) => a({
            previousState: l,
            state: n.value,
            action: i,
            dispatch: o
          })
        );
      };
    });
    /**
     * Creates an isomorphism for the Signal.
     * An isomorphism is a pair of functions that convert values between two types,
     * along with an equality function to compare values of the second type.
     *
     * @param to - A function that converts values from type T to type O.
     * @param from - A function that converts values from type O to type T.
     * @param equals - An optional function that compares values of type O for equality.
     *                Defaults to a strict equality check (===).
     * @returns A Prop object representing the isomorphism.
     */
    u(this, "iso", (r, s, n = (o, i) => o === i) => {
      const o = new q(r(this.get()), n);
      return o.onDispose(this.on((i) => o.set(r(i)))), o.on((i) => this._setAndNotify(s(i), !1)), o;
    });
    /**
     * Returns a `Prop` that represents the value at the specified key of the current value.
     *
     * @param key - The key of the value to access.
     * @returns A `Prop` that represents the value at the specified key.
     */
    u(this, "atProp", (r) => this.iso(
      (s) => s[r],
      (s) => ({ ...this.value, [r]: s })
    ));
  }
  /**
   *  Access for the current value of the property.
   */
  get value() {
    return this.get();
  }
  set value(r) {
    this._setAndNotify(r, !1);
  }
};
/**
 * Checks if a value is a Prop.
 * @param value - The value to check.
 * @returns `true` if the value is a Prop, `false` otherwise.
 */
u(q, "is", (r) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  r != null && r.$__prop__ === !0
));
let F = q;
const X = (t, e, r = (s, n) => s === n) => {
  const s = new E(t, r);
  return e.forEach((n) => n.setDerivative(s)), s;
}, Ue = (t, e) => X(t, e).dispose, V = (t, e = (r, s) => r === s) => new F(t, e), I = (t, e = (r, s) => r === s) => new h(t, e), d = {
  /**
   * Maps a value or a Signal to a new value.
   * If the value is a Signal, it returns a new Signal with the mapped value.
   * If the value is not a Signal, it returns the mapped value.
   *
   * @typeParam T - The type of the value.
   * @typeParam U - The type of the new value.
   * @param value - The value or Signal to map.
   * @param fn - The function to map the value.
   * @returns The mapped value.
   */
  map: (t, e) => h.is(t) ? t.map(e) : e(t),
  /**
   * Wraps a value or a Signal instance into a Signal.
   * If the value is already a Signal, it returns the value itself.
   * If the value is not a Signal, it creates a new Signal instance with the given value.
   *
   * @typeParam O - The type of the value.
   * @param value - The value or Signal instance to wrap.
   * @param equals - A function that determines if two values are equal. Defaults to strict equality (===).
   * @returns A Signal instance.
   */
  toSignal: (t, e) => h.is(t) ? t : I(t, e),
  /**
   * Wraps a value in a `Signal` if it is not already a `Signal`.
   * If the value is `null` or `undefined`, it returns `null` or `undefined` respectively.
   * @param value - The value to wrap or check.
   * @returns The wrapped value if it is not `null` or `undefined`, otherwise `null` or `undefined`.
   */
  maybeToSignal: (t, e) => {
    if (t != null)
      return d.toSignal(t, e);
  },
  /**
   * Gets the value from a `Signal` or the value itself if it is not a `Signal`.
   * @param value - The value or Signal instance to get the value from.
   * @returns The value.
   */
  get: (t) => h.is(t) ? t.get() : t,
  /**
   * Adds a listener to a `Signal` or calls the listener immediately if it is not a `Signal`.
   * @param value - The value or Signal instance to add the listener to.
   * @param listener - The listener to call when the value changes.
   * @returns A function to remove the listener.
   */
  on: (t, e) => h.is(t) ? t.on(e) : (e(t), () => {
  }),
  /**
   * Disposes of a value or a Signal.
   * If the value is a Signal, it disposes of the Signal.
   * If the value is not a Signal, it does nothing.
   * @param value - The value or Signal instance to dispose of.
   */
  dispose: (t) => {
    h.is(t) && t.dispose();
  }
}, Pt = (...t) => (e, r) => {
  const s = t.filter((n) => h.is(n));
  return X(
    () => e(...t.map((n) => d.get(n))),
    s,
    r
  );
}, Dt = (...t) => (e) => {
  const r = t.filter((s) => h.is(s));
  Ue(() => e(...t.map(d.get)), r);
};
class se {
  constructor() {
    u(this, "_store", /* @__PURE__ */ new Map());
    /**
     * Retrieves the value associated with the specified key from the memory store.
     * @param key - The key to retrieve the value for.
     * @returns The value associated with the key, or `null` if the key is not found.
     */
    u(this, "getItem", (e) => this._store.get(e) ?? null);
    /**
     * Sets the value associated with the specified key in the memory store.
     * @param key - The key to set the value for.
     * @param value - The value to set.
     */
    u(this, "setItem", (e, r) => {
      this._store.set(e, r);
    });
  }
}
const ne = ({
  key: t,
  defaultValue: e,
  store: r,
  serialize: s = JSON.stringify,
  deserialize: n = JSON.parse,
  equals: o = (l, a) => l === a,
  onLoad: i = (l) => l
}) => {
  const l = r.getItem(t), a = new F(
    l != null ? i(n(l)) : typeof e == "function" ? e() : e,
    o
  );
  return a.on((f) => {
    r.setItem(t, s(f));
  }), a;
}, bt = (t) => ne({
  ...t,
  store: (window == null ? void 0 : window.localStorage) ?? new se()
}), Tt = (t) => ne({
  ...t,
  store: (window == null ? void 0 : window.sessionStorage) ?? new se()
});
function re(t) {
  return typeof requestAnimationFrame == "function" ? requestAnimationFrame(t) : setTimeout(t, 0);
}
const Ge = (t, e, r, s) => {
  const n = (s == null ? void 0 : s.duration) ?? 300, o = (s == null ? void 0 : s.easing) ?? ((_) => _), i = (s == null ? void 0 : s.equals) ?? ((_, R) => _ === R);
  let l = s == null ? void 0 : s.interpolate, a = t, f = e(), g = performance.now(), w = null, U = !0;
  const G = new E(e, i), A = V(t, i);
  A.onDispose(() => {
    w !== null && cancelAnimationFrame(w);
  }), A.onDispose(G.dispose), r.forEach((_) => {
    _.setDerivative(G), _.onDispose(A.dispose);
  });
  const Ne = (_) => {
    f = _, g = performance.now(), a = A.value, U && (U = !1, w = re(Z));
  }, Z = () => {
    const R = (performance.now() - g) / d.get(n), Re = o(R);
    l == null && (l = Be(a));
    let K = l(a, f, Re);
    R >= 1 ? (U = !0, K = f) : w = re(Z), A.set(K);
  };
  return G.on(Ne), A;
}, Ot = (t, e) => {
  const { initialValue: r, ...s } = e ?? {};
  return Ge(
    r ?? t.get(),
    t.get,
    [t],
    s
  );
}, Ct = (t, e) => {
  const { signals: r, literals: s } = Object.entries(t).reduce(
    ({ signals: o, literals: i }, [l, a]) => (h.is(a) ? o.push([l, a]) : i[l] = a, { signals: o, literals: i }),
    { signals: [], literals: {} }
  ), n = r.map(([, o]) => o);
  return X(() => (r.forEach(([o, i]) => s[o] = i.value), e(s)), n);
}, oe = /* @__PURE__ */ new Set(["checked", "disabled", "hidden", "selected"]), ie = /* @__PURE__ */ new Set([
  "rowSpan",
  "colSpan",
  "tabIndex",
  "valueAsNumber"
]), le = /* @__PURE__ */ new Set(["valueAsDate"]), ue = /* @__PURE__ */ new Set([
  "value",
  "textContent",
  "innerText",
  "innerHTML",
  "outerHTML",
  "className",
  "classList"
]), W = /* @__PURE__ */ new Map(), b = (t, e) => {
  if (W.has(t))
    return W.get(t);
  {
    const r = e(t);
    return W.set(t, r), r;
  }
}, Je = (t) => (e, r) => {
  r == null ? e[t] = null : e[t] = !!r;
}, We = (t) => (e, r) => {
  r == null ? e[t] = null : e[t] = Number(r);
}, He = (t) => (e, r) => {
  r == null ? e[t] = null : e[t] = r;
}, ze = (t) => (e, r) => {
  r == null ? e[t] = null : e[t] = String(r);
}, Xe = (t) => (e, r) => {
  r == null ? e.removeAttribute(t) : e.setAttribute(t, r);
}, ae = (t) => oe.has(t) ? b(t, Je) : ie.has(t) ? b(t, We) : le.has(t) ? b(t, He) : ue.has(t) ? b(t, ze) : b(t, Xe), ce = (t) => (e) => oe.has(t) ? !!e[t] : ie.has(t) ? Number(e[t]) : le.has(t) ? e[t] : ue.has(t) ? String(e[t]) : e.getAttribute(t);
class y {
  /**
   * Constructs a new `DOMContext` instance.
   *
   * @param document - The `Document` instance associated with this context.
   * @param element - The `Element` instance associated with this context.
   * @param reference - An optional `Node` instance that serves as a reference for this context.
   * @param providers - The `Providers` instance associated with this context.
   * @param isFirstLevel - A boolean value indicating whether this context is at the first level, meaning the outermost node in the generated DOM.
   */
  constructor(e, r, s, n, o) {
    /**
     * Creates a new DOM element (eg: HTML or SVG) with the specified tag name and namespace.
     *
     * @param tagName - The tag name of the element to create.
     * @param namespace - The namespace URI to create the element in, or `undefined` to create a standard HTML element.
     * @returns The newly created element.
     */
    u(this, "createElement", (e, r) => r !== void 0 ? this.document.createElementNS(r, e) : this.document.createElement(e));
    /**
     * Creates a new text node with the specified text content.
     * @param text - The text content for the new text node.
     * @returns A new `Text` node with the specified text content.
     */
    u(this, "createText", (e) => this.document.createTextNode(e));
    /**
     * Creates a new `DOMContext` with a reference to a newly created text node.
     * The text node is appended or inserted to the current `DOMContext`.
     * The new `DOMContext` with the reference is returned.
     */
    u(this, "makeRef", () => {
      const e = this.createText("");
      return this.appendOrInsert(e), this.withReference(e);
    });
    /**
     * Appends or inserts a child node to the element, depending on whether a reference node is provided.
     *
     * @param child - The child node to append or insert.
     */
    u(this, "appendOrInsert", (e) => {
      this.reference === void 0 ? this.element.appendChild(e) : this.element.insertBefore(e, this.reference);
    });
    /**
     * Creates a new `DOMContext` instance with the provided `document`.
     *
     * @param document - The `Document` to use for the `DOMContext`.
     * @returns A new `DOMContext` instance.
     */
    u(this, "withDocument", (e) => new y(e, this.element, this.reference, this.providers, !0));
    /**
     * Creates a new `DOMContext` instance with the provided `element`.
     * @param element - The DOM element to use in the new `DOMContext` instance.
     * @returns A new `DOMContext` instance with the provided `element`.
     */
    u(this, "withElement", (e) => new y(this.document, e, void 0, this.providers, !1));
    /**
     * Creates a new `DOMContext` instance with the `isFirstLevel` property set to `true`.
     * @returns A new `DOMContext` instance with the `isFirstLevel` property set to `true`.
     */
    u(this, "withFirstLevel", () => new y(
      this.document,
      this.element,
      this.reference,
      this.providers,
      !0
    ));
    /**
     * Creates a new `DOMContext` instance with the specified reference.
     *
     * @param reference - The optional `Text` node to use as the reference for the new `DOMContext`.
     * @returns A new `DOMContext` instance with the specified reference.
     */
    u(this, "withReference", (e) => new y(
      this.document,
      this.element,
      e,
      this.providers,
      this.isFirstLevel
    ));
    /** Creates a new DOMContext with the provided provider value.
     *
     * @param mark - The provider mark to associate the value with.
     * @param value - The value to set for the provider.
     * @returns A new DOMContext with the updated providers.
     */
    u(this, "withProvider", (e, r) => new y(
      this.document,
      this.element,
      this.reference,
      {
        ...this.providers,
        [e]: r
      },
      this.isFirstLevel
    ));
    /**
     * Returns a new DOMContext instance with the specified providers merged into
     * the existing providers.
     *
     * @param providers - An object containing the providers to be merged into the existing providers.
     * @returns A new DOMContext instance with the merged providers.
     */
    u(this, "withProviders", (e) => new y(
      this.document,
      this.element,
      this.reference,
      {
        ...this.providers,
        ...e
      },
      this.isFirstLevel
    ));
    /**
     * Retrieves a provider for the given provider mark.
     *
     * @param mark - The provider mark to retrieve the provider for.
     * @returns The provider for the given mark.
     * @throws Throws `ProviderNotFoundError` if the provider for the given mark is not found.
     */
    u(this, "getProvider", (e) => {
      if (this.providers[e] === void 0)
        throw new Qe(e);
      return this.providers[e];
    });
    this.document = e, this.element = r, this.reference = s, this.providers = n, this.isFirstLevel = o;
  }
  /**
   * Creates a new `DOMContext` instance for the given `Element` and optional reference `Node`.
   *
   * @param element - The `Element` to create the `DOMContext` for.
   * @param ref - An optional reference `Node` to associate with the `DOMContext`.
   * @returns A new `DOMContext` instance.
   */
  static of(e, r) {
    return new y(e.ownerDocument, e, r, {}, !0);
  }
}
class Qe extends Error {
  constructor(e) {
    super(`Provider not found: ${e.description}`);
  }
}
const m = (t) => {
  const e = t;
  e && e.onblur && (e.onblur = null), !(!t || t.ownerDocument === void 0) && t.parentElement && t.parentElement.removeChild(t);
}, Ye = (t) => fe(t) ? t : t.parentElement, fe = (t) => t.nodeType === 1, he = (t) => (e) => {
  Se(e);
  const r = e.createText(t);
  return e.appendOrInsert(r), (s) => {
    s && m(r);
  };
}, de = (t) => (e) => {
  Se(e);
  const r = e.createText(t.value);
  e.appendOrInsert(r);
  const s = t.on((n) => r.data = n);
  return (n) => {
    s(), n && m(r);
  };
}, Lt = (t) => h.is(t) ? de(t) : he(t), p = (...t) => (e) => {
  const r = t.map((s) => c(s)(e));
  return (s) => {
    r.forEach((n) => n(s));
  };
}, S = () => () => {
}, Ze = (t) => (e) => (ye(e), e.element.classList.add(...t), (r) => {
  r && e.element.classList.remove(...t);
}), Ke = (t) => (e) => {
  ye(e);
  const r = e.element;
  let s = [];
  const n = t.on((o) => {
    s.forEach((i) => r.classList.remove(i)), s = (o ?? "").split(" ").filter((i) => i.length > 0), r.classList.add(...s);
  });
  return (o) => {
    n(), o && s.forEach((i) => r.classList.remove(i)), s.length = 0;
  };
}, L = (t, e) => {
  const r = ae(t), s = ce(t);
  return (n) => {
    _e(n, t);
    const o = s(n.element);
    return r(n.element, e), (i) => {
      i && r(n.element, o);
    };
  };
}, k = (t, e) => {
  const r = ae(t), s = ce(t);
  return (n) => {
    _e(n, t);
    const o = s(n.element), i = e.on((l) => r(n.element, l));
    return (l) => {
      i(), l && r(n.element, o);
    };
  };
}, P = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified attribute.
     *
     * Generally using multiple attributes with the same name is not recommended.
     * `class` is the exception and can be used multiple times.
     *
     * @param _ - The target object.
     * @param name - The name of the attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (t, e) => e === "class" ? (r) => h.is(r) ? Ke(r) : Ze(
      (r ?? "").split(" ").filter((s) => s.length > 0)
    ) : (r) => h.is(r) ? k(
      e,
      r
    ) : L(
      e,
      r
    )
  }
), kt = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `data-?` attribute.
     *
     * @param _ - The target object.
     * @param name - The name of the data attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (t, e) => (r) => h.is(r) ? k(
      `data-${e}`,
      r
    ) : L(`data-${e}`, r)
  }
), Nt = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `aria-?` attribute.
     *
     * @param _ - The target object.
     * @param name - The name of the aria attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (t, e) => (r) => h.is(r) ? k(
      `aria-${e}`,
      r
    ) : L(
      `aria-${e}`,
      r
    )
  }
), Rt = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `svg` attribute.
     *
     * @param _ - The target object.
     * @param name - The name of the SVG attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (t, e) => (r) => h.is(r) ? k(
      e,
      r
    ) : L(
      e,
      r
    )
  }
), Mt = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `math` attribute.
     *
     * @param name - The name of the Math attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (t, e) => (r) => h.is(r) ? k(
      e,
      r
    ) : L(
      e,
      r
    )
  }
), c = (t) => t == null ? S : Array.isArray(t) ? p(...t.map(c)) : typeof t == "string" ? he(t) : h.is(t) ? de(t) : t, pe = (t, ...e) => (r) => {
  const s = r.createElement(t, void 0);
  r.isFirstLevel && N() && ve(s), r.appendOrInsert(s), r = r.withElement(s);
  const n = e.map((o) => c(o)(r));
  return (o) => {
    n.forEach((i) => i(!1)), o && m(s);
  };
}, me = (t, e, ...r) => (s) => {
  const n = s.createElement(t, e);
  s.isFirstLevel && N() && ve(n), s.appendOrInsert(n), s = s.withElement(n);
  const o = r.map((i) => c(i)(s));
  return (i) => {
    o.forEach((l) => l(!1)), i && m(n);
  };
}, $t = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an HTML element.
     * @param tagName - The HTML tag name.
     * @returns A renderable function that creates and appends the HTML element to the DOM.
     */
    get: (t, e) => (...r) => pe(e, r.flatMap(c))
  }
), Ft = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an HTMLInput element.
     * @param type - The input type name.
     * @returns A renderable function that creates and appends the HTMLInput element to the DOM.
     */
    get: (t, e) => (...r) => pe("input", P.type(e), ...r)
  }
), xe = "http://www.w3.org/2000/svg", Vt = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an SVG element.
     * @param tagName - The SVG tag name.
     * @returns A renderable function that creates and appends the SVG element to the DOM.
     */
    get: (t, e) => (...r) => me(e, xe, r.flatMap(c))
  }
), et = "http://www.w3.org/1998/Math/MathML", qt = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an Math element.
     * @param tagName - The Math tag name.
     * @returns A renderable function that creates and appends the Math element to the DOM.
     */
    get: (t, e) => (...r) => me(e, et, r.flatMap(c))
  }
), O = "data-tempo-attr", M = "data-tempo-class", ge = "data-tempo-node", $ = "data-tempo-text", tt = (t, e) => {
  const r = t.getAttribute(e);
  if (r != null) {
    const s = t.getAttribute(O) ?? "{}", n = { ...JSON.parse(s), name: r };
    t.setAttribute(
      O,
      JSON.stringify(n).replace(/"/g, "&quot;")
    );
  }
}, _e = (t, e) => {
  N() && t.isFirstLevel && tt(t.element, e);
}, rt = (t) => {
  t.querySelectorAll(`[${O}]`).forEach((e) => {
    const r = JSON.parse(
      (e.getAttribute(O) ?? "{}").replace(/&quot;/g, '"')
    );
    for (const [s, n] of Object.entries(r))
      e.setAttribute(s, n);
    e.removeAttribute(O);
  });
};
function st(t) {
  t.setAttribute(M, t.className);
}
const ye = (t) => {
  N() && t.isFirstLevel && st(t.element);
}, nt = (t) => {
  t.querySelectorAll(`[${M}]`).forEach((e) => {
    const r = e.getAttribute(M);
    r !== null && (e.className = r, e.removeAttribute(M));
  });
}, ve = (t) => {
  t.setAttribute(ge, "");
}, ot = (t) => {
  t.querySelectorAll(`[${ge}]`).forEach((e) => {
    m(e);
  });
}, it = (t) => {
  t.setAttribute($, t.textContent ?? "");
}, Se = (t) => {
  N() && t.isFirstLevel && it(t.element);
}, lt = (t) => {
  t.querySelectorAll(`[${$}]`).forEach((e) => {
    e.textContent = e.getAttribute($), e.removeAttribute($);
  });
}, ut = (t) => {
  ot(t), nt(t), rt(t), lt(t);
}, we = () => {
  const t = globalThis;
  return t.__tempoSSR__ == null && (t.__tempoSSR__ = {
    isSSR: !1,
    counter: 0
  }), t.__tempoSSR__;
}, Q = (t, e) => {
  const r = we();
  r[t] = e;
}, Ae = (t) => we()[t], H = (t) => {
  Q("isSSR", t);
}, Y = () => Ae("counter"), at = () => {
  Q("counter", (Y() ?? 0) + 1);
}, ct = () => {
  Q("counter", (Y() ?? 0) - 1);
}, It = (t = 30) => (H(!0), new Promise((e, r) => {
  let s;
  const n = setInterval(() => {
    Y() <= 0 && (clearInterval(n), clearTimeout(s), H(!1), e());
  }, 30);
  s = setTimeout(() => {
    clearInterval(n), H(!1), r(new Error("SSR Timeout"));
  }, t * 1e3);
})), Bt = (t) => (at(), c(t(ct))), N = () => Ae("isSSR"), Ee = (t, e) => {
  if (typeof e == "function")
    return Ee(t, { then: e });
  const r = e.pending != null ? c(e.pending) : S, s = e.then, n = e.error != null ? (o) => c(e.error(o)) : () => S;
  return (o) => {
    let i = !0;
    const l = t();
    o = o.makeRef();
    let a = c(r)(o);
    return l.then(
      (f) => {
        i && (a(!0), a = c(s(f))(o));
      },
      (f) => {
        i && (a(!0), a = c(n(f))(o));
      }
    ), (f) => {
      i = !1, a(f), f && o.reference && m(o.reference);
    };
  };
}, jt = (t, e) => Ee(() => t, e), Pe = (t, e) => (r) => (r.element.addEventListener(t, e), (s) => {
  s && r.element.removeEventListener(t, e);
}), ft = (t) => Pe("click", (e) => {
  e.preventDefault();
  const r = e.target;
  setTimeout(() => {
    const s = r.ownerDocument != null ? r == null ? void 0 : r.checked : void 0;
    s != null && t(!s);
  }, 0);
}), B = new Proxy(
  {},
  {
    /**
     * @param name - The name of the event handler.
     * @param fn - The function to call when the event is triggered.
     * @returns A `Renderable` function that adds the event listener to the element.
     */
    get: (t, e) => (r) => Pe(e, r)
  }
), ht = (t) => (e) => {
  const r = e.target;
  t(r.value);
}, dt = (t) => (e) => {
  const r = e.target;
  t(r.valueAsNumber);
}, pt = (t) => (e) => {
  const r = e.target;
  if (r.value === "")
    return;
  const s = r.value.split("-"), n = new Date(
    Number(s[0]),
    Number(s[1]) - 1,
    Number(s[2].substring(0, 2))
  );
  t(n);
}, mt = (t) => (e) => {
  const r = e.target;
  if (r.value === "")
    return;
  const s = r.value.split("T"), n = s[0].split("-"), o = new Date(
    Number(n[0]),
    Number(n[1]) - 1,
    Number(n[2])
  ), i = s[1].split(":");
  o.setHours(Number(i[0])), o.setMinutes(Number(i[1])), o.setSeconds(Number(i[2])), t(o);
}, Ut = (t) => (e) => {
  const r = e.target;
  t(r.checked);
}, Gt = (t) => (e) => {
  e.preventDefault(), t();
}, Jt = (t) => (e) => {
  e.stopPropagation(), t();
}, Wt = (t) => (e) => {
  e.stopImmediatePropagation(), t();
}, Ht = (t, e = "input") => p(
  P.valueAsDate(t),
  B[e](pt(t.set))
), zt = (t, e = "input") => p(
  P.valueAsDate(t),
  B[e](mt(t.set))
), Xt = (t, e = "input") => p(
  P.valueAsNumber(t),
  B[e](dt(t.set))
), Qt = (t, e = "input") => p(P.value(t), B[e](ht(t.set))), Yt = (t) => p(P.checked(t), ft(t.set)), j = (t, e) => {
  if (h.is(t))
    return (s) => {
      s = s.makeRef();
      let n, o;
      const i = t.map((f) => Object.keys(f)[0]);
      let l;
      const a = i.on((f) => {
        if (f !== l) {
          o == null || o.dispose(), n == null || n(!0), o = t.map((w) => w[f]);
          const g = e[f](o);
          n = c(g)(s), l = f;
        }
      });
      return (f) => {
        a(), f && s.reference != null && m(s.reference), n == null || n(!0);
      };
    };
  const r = Object.keys(t)[0];
  return c(e[r](I(t[r])));
}, De = (t, e, r) => j(
  d.map(t, (s) => ({ [s[e]]: s })),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  r
), Zt = (t, e) => De(t, "kind", e), Kt = (t, e) => {
  const r = d.map(t, ([s, n]) => ({ [s]: n }));
  return j(r, e);
}, xt = (t, e) => De(t, "type", e), gt = (t, e) => j(
  d.map(t, (r) => ({ [r]: !0 })),
  e
), er = (t, e = {}) => (r) => {
  const s = (e == null ? void 0 : e.firstSeparator) ?? t, n = (e == null ? void 0 : e.lastSeparator) ?? t;
  return gt(
    r.map((o) => o.isFirst ? "first" : o.isLast ? "last" : "other"),
    {
      first: () => s,
      last: () => n,
      other: () => t
    }
  );
}, be = (t, e) => (r) => {
  const s = Object.values(t).reduce((n, o) => {
    const i = r.getProvider(o);
    if (i == null)
      throw new Error(`No provider found for mark: ${o.description}`);
    return n[o] = i, n;
  }, {});
  return c(e(s))(r);
}, tr = (t, e) => (r) => {
  const s = [], n = Object.entries(t).reduce(
    (o, [i, l]) => (s.push(
      l((a) => (Reflect.set(o, i, a), null))(r)
    ), o),
    {}
  );
  return s.push(e(n)(r)), (o) => {
    s.forEach((i) => i(o));
  };
}, rr = (t, e) => be([t], (r) => c(e(r[t]))), sr = (t, e) => be(t, (r) => c(e(r))), nr = (t) => (e) => t(e), or = (t) => (e) => (e.appendOrInsert(t), (r) => {
  r && m(t);
}), _t = (t, e, r) => {
  if (h.is(t)) {
    const s = t;
    return (n) => {
      n = n.makeRef();
      let o = null, i = !1;
      const l = V(null), a = s.on((f) => {
        f == null ? (o == null || o(!0), o = c((r == null ? void 0 : r()) ?? S)(n), i = !1) : (l.value = f, i || (o == null || o(!0), o = c(e(l))(n), i = !0));
      });
      return (f) => {
        a(), o == null || o(f), f && n.reference && m(n.reference);
      };
    };
  } else {
    const s = t;
    if (s == null) {
      const n = r == null ? void 0 : r();
      return n != null ? c(n) : S;
    }
    return c(e(I(s)));
  }
}, z = (t) => (e) => (r) => t(r, e), Te = (t, e, r) => Oe(
  t,
  () => e,
  () => r
), Oe = (t, e, r) => _t(
  d.map(t, (s) => s ? !0 : null),
  e,
  r ?? void 0
), ir = (t, e, r) => yt(
  t,
  () => e,
  () => r
), yt = (t, e, r) => Oe(
  d.map(t, (s) => !s),
  e,
  r
), Ce = (t, e, r) => r != null ? Ce(t, (s) => {
  const n = new T(
    s.index,
    s.total.map((o) => o - 1)
  );
  return p(
    z(n.dispose),
    c(e(s)),
    Te(s.isLast, S, r(n))
  );
}) : h.is(t) ? (s) => {
  s = s.makeRef();
  const n = Array.from(
    { length: t.value },
    (l, a) => a
  ).map((l) => new T(l, t)), o = n.map(
    (l) => c(e(l))(s)
  ), i = t.on((l) => {
    for (; l < o.length; )
      n.pop().dispose(), o.pop()(!0);
    for (let a = 0; a < l; a++)
      if (n[a] == null) {
        n[a] = new T(a, t);
        const f = c(e(n[a]));
        o[a] = f(s);
      }
  });
  return (l) => {
    i(), l && s.reference && m(s.reference);
  };
} : p(
  ...Array.from({ length: t }, (s, n) => n).map(
    (s) => c(e(new T(s, I(t))))
  )
), vt = (t, e, r) => {
  if (r != null)
    return vt(t, (s, n) => {
      const o = new T(
        n.index,
        n.total.map((i) => i - 1)
      );
      return p([
        z(o.dispose),
        c(e(s, n)),
        Te(n.isLast, S, r(o))
      ]);
    });
  {
    const s = d.map(t, (o) => o.length), n = d.toSignal(t);
    return Ce(s, (o) => {
      const i = n.map((l) => l[o.index]);
      return p(
        z(i.dispose),
        c(e(i, o))
      );
    });
  }
}, lr = (t, e) => {
  if (h.is(t)) {
    const r = t;
    return (s) => {
      s = s.makeRef();
      const n = r.map((l) => c(e(l)));
      let o = () => {
      };
      const i = n.on((l) => {
        o(!0), o = l(s);
      });
      return (l) => {
        i(), o(l);
      };
    };
  }
  return c(e(t));
}, ur = (t, e, r = () => S) => j(
  d.map(
    t,
    (s) => s.length > 0 ? { notEmpty: s } : { whenEmpty: null }
  ),
  {
    notEmpty: (s) => e(s),
    whenEmpty: () => r()
  }
), ar = (t) => (e) => t(e.element) ?? (() => {
}), Le = (t, e) => {
  const r = t(e);
  return () => r(!0);
}, cr = (t, e, { doc: r, clear: s } = {}) => {
  const n = typeof e == "string" ? (r ?? document).querySelector(e) : e;
  if (n === null)
    throw new St(
      `Cannot find element by selector for render: ${e}`
    );
  s !== !1 && (r ?? n.ownerDocument) != null && ut(r ?? n.ownerDocument);
  const o = Ye(n), i = fe(n) ? void 0 : n, l = y.of(o, i);
  return Le(t, l);
};
class St extends Error {
  constructor(e) {
    super(e);
  }
}
const fr = (t, e) => (r) => {
  const s = r.document.querySelector(t);
  if (s === null)
    throw new Error(`Cannot find element by selector for portal: ${t}`);
  return Le(
    c(e),
    r.withElement(s).withFirstLevel()
  );
}, hr = (t) => Symbol(t), ke = (t, e) => (r) => c(e)(r.withProviders(t)), dr = (...t) => t.length > 0 ? t.reduceRight((e, r) => (s) => e(r(s))) : c, pr = (t, e, r) => ke({ [t]: e }, c(r)), mr = (t, e) => ke(t, c(e)), wt = (t, e) => (r) => {
  const s = r.element, n = s.style.getPropertyValue(t);
  return s.style.setProperty(t, e), (o) => {
    o && s.style.setProperty(t, n);
  };
}, At = (t, e) => (r) => {
  const s = r.element, n = s.style.getPropertyValue(t);
  return e.on((o) => s.style.setProperty(t, o)), (o) => {
    o && s.style.setProperty(t, n);
  };
}, gr = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `style` property.
     *
     * @param _ - The target object.
     * @param name - The name of the CSS style property.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (t, e) => (r) => h.is(r) ? At(e, r) : wt(e, r)
  }
);
export {
  jt as Async,
  Yt as BindChecked,
  Ht as BindDate,
  zt as BindDateTime,
  Xt as BindNumber,
  Qt as BindText,
  E as Computed,
  er as Conjunction,
  y as DOMContext,
  or as DOMNode,
  pe as El,
  me as ElNS,
  T as ElementPosition,
  S as Empty,
  _t as Ensure,
  vt as ForEach,
  p as Fragment,
  yt as LazyUnless,
  Oe as LazyWhen,
  lr as MapSignal,
  se as MemoryStore,
  ur as NotEmpty,
  ft as OnChecked,
  nr as OnCtx,
  ar as OnMount,
  z as OnUnmount,
  j as OneOf,
  De as OneOfField,
  Zt as OneOfKind,
  Kt as OneOfTuple,
  xt as OneOfType,
  gt as OneOfValue,
  fr as Portal,
  F as Prop,
  dr as Provide,
  Qe as ProviderNotFoundError,
  St as RenderingError,
  Ce as Repeat,
  h as Signal,
  Ee as Task,
  Lt as TextNode,
  ir as Unless,
  tr as Use,
  rr as UseProvider,
  sr as UseProviders,
  Bt as UseSSRDone,
  d as Value,
  Te as When,
  pr as WithProvider,
  mr as WithProviders,
  ve as _addNodeTracker,
  ut as _clearSSR,
  Ye as _getSelfOrParentElement,
  fe as _isElement,
  ce as _makeGetter,
  ae as _makeSetter,
  _e as _maybeAddAttributeTracker,
  ye as _maybeAddClassTracker,
  Se as _maybeAddTextTracker,
  m as _removeDOMNode,
  Xe as _setAttribute,
  Je as _setBooleanProperty,
  He as _setDateProperty,
  We as _setNumberProperty,
  ze as _setStringProperty,
  de as _signalText,
  he as _staticText,
  Ot as animateSignal,
  Ge as animateSignals,
  Nt as aria,
  P as attr,
  kt as dataAttr,
  Ut as emitChecked,
  Gt as emitPreventDefault,
  Wt as emitStopImmediatePropagation,
  Jt as emitStopPropagation,
  ht as emitValue,
  pt as emitValueAsDate,
  mt as emitValueAsDateTime,
  dt as emitValueAsNumber,
  Ie as endInterpolate,
  Be as guessInterpolate,
  $t as html,
  Ft as input,
  qe as interpolateDate,
  Fe as interpolateNumber,
  Ve as interpolateString,
  N as isSSR,
  bt as localStorageProp,
  X as makeComputed,
  Pt as makeComputedOf,
  Ct as makeComputedRecord,
  Ue as makeEffect,
  Dt as makeEffectOf,
  V as makeProp,
  hr as makeProviderMark,
  I as makeSignal,
  qt as math,
  Mt as mathAttr,
  B as on,
  It as prepareSSR,
  lt as removeTextTrackers,
  cr as render,
  Le as renderWithContext,
  c as renderableOfTNode,
  Tt as sessionStorageProp,
  ne as storedProp,
  gr as style,
  Vt as svg,
  Rt as svgAttr
};

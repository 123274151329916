import { objectKeys as v } from "./object.js";
const q = (t, n) => Array.from({ length: t.length }, (e, r) => n(t[r], r)), B = (t, n) => {
  const e = [];
  for (const r of t)
    e.push(...n(r));
  return e;
}, _ = (t) => t.length > 0 ? t[0] : void 0, k = (t) => t.slice(1), F = (t, n, e) => {
  if (t.length !== n.length) return !1;
  for (let r = 0; r < t.length; r++)
    if (!e(t[r], n[r])) return !1;
  return !0;
}, I = (t) => t.length === 0, M = (t) => t.length > 0, x = (t, n) => {
  const e = [];
  for (const r of t) n(r) && e.push(r);
  return e;
}, b = (t, n) => {
  const e = [];
  for (let r = 0; r < t.length; r++) {
    const l = n(t[r], r);
    l != null && e.push(l);
  }
  return e;
}, j = (t) => x(t, (n) => n != null), D = (t) => [].concat(...t), P = (t, n, e) => {
  for (const r of t)
    e = n(e, r);
  return e;
}, S = (t, n) => {
  for (const e of t)
    if (!n(e))
      return !1;
  return !0;
}, N = (t, n) => {
  for (const e of t)
    if (n(e))
      return !0;
  return !1;
}, T = (t, n) => {
  for (const e of t) n(e);
}, $ = (...t) => [].concat(...t), H = (t, n, e, r = !0) => {
  if (t.length < n.length)
    return -1 * (r ? 1 : -1);
  if (t.length > n.length)
    return 1 * (r ? 1 : -1);
  for (let l = 0; l < t.length; l++) {
    const a = e(t[l], n[l]);
    if (a !== 0) return a;
  }
  return 0;
}, C = (t, n) => t.slice().sort(n), g = (t, n) => Array.from({ length: t }, (e, r) => n(r)), L = (t, n = 0) => g(t, (e) => n + e), R = (t, n) => g(t, () => n), V = (t) => Array.from(new Set(t)), W = (t, n) => {
  const e = {};
  return t.forEach((r) => {
    e[n(r)] = r;
  }), v(e).map((r) => e[r]);
}, E = (t, n) => {
  const e = t.indexOf(n);
  return e < 0 ? !1 : (t.splice(e, 1), !0);
}, z = (t, n) => {
  let e = !1;
  for (; E(t, n); )
    e = !0;
  return e;
}, w = (t, n) => {
  const e = t.findIndex(n);
  return e < 0 ? !1 : (t.splice(e, 1), !0);
}, G = (t, n) => {
  let e = !1;
  for (; w(t, n); )
    e = !0;
  return e;
}, J = (t) => {
  const n = [];
  for (let e = t.next(); !(e.done ?? !1); e = t.next())
    n.push(e.value);
  return n;
}, Q = (t, n, e) => {
  const r = {
    removals: [],
    swaps: [],
    inserts: []
  }, { removals: l, inserts: a, swaps: u } = r, c = /* @__PURE__ */ new Map();
  n.forEach((s, o) => c.set(e(s), o));
  const f = t.map((s, o) => [s, o]).filter(([s]) => !c.has(e(s))).map(([s, o]) => o);
  for (let s = f.length - 1; s >= 0; s--) {
    const o = f[s], i = l.length > 0 ? l[l.length - 1] : void 0;
    i != null && i.at === o + 1 ? (i.at--, i.qt++) : l.push({ at: o, qt: 1 });
  }
  const h = /* @__PURE__ */ new Map();
  t.forEach((s, o) => h.set(e(s), o));
  const y = n.map((s, o) => [s, o]).filter(([s]) => !h.has(e(s))).map(([s, o]) => o);
  for (const s of y) {
    const o = a.length > 0 ? a[a.length - 1] : void 0;
    o != null && o.at + o.values.length === s ? o.values.push(n[s]) : a.push({ at: s, values: [n[s]] });
  }
  const p = t.filter((s, o) => !f.includes(o)), m = /* @__PURE__ */ new Map();
  for (let s = 0; s < p.length; s++)
    m.set(e(p[s]), s);
  const A = n.filter((s, o) => !y.includes(o));
  for (let s = 0; s < A.length; s++) {
    const o = e(A[s]), i = m.get(o);
    if (i == null || s === i) continue;
    const d = e(p[s]);
    m.delete(d), u.push({ from: s, to: i });
  }
  return r;
}, U = (t, n) => {
  const e = [...n];
  for (const { at: r, qt: l } of t.removals)
    e.splice(r, l);
  for (const { from: r, to: l } of t.swaps) {
    const a = e[l];
    e[l] = e[r], e[r] = a;
  }
  for (const r of t.inserts)
    e.splice(r.at, 0, ...r.values);
  return e;
}, X = (t, n = " and ", e = ", ") => t.length === 0 ? "" : t.length === 1 ? String(t[0]) : `${t.slice(0, -1).join(e)}${n}${String(t[t.length - 1])}`, Y = (t, n, e = !0) => {
  const r = t.map((c, f) => [c, f]);
  r.sort((c, f) => n(c[0], f[0]));
  const l = new Array(r.length);
  let a = 0, u = r[0][0];
  for (let c = 0; c < r.length; c++) {
    const [f, h] = r[c];
    n(f, u) !== 0 && (a = c, u = f), l[h] = a, e && a++;
  }
  return l;
};
export {
  S as allElements,
  N as anyElement,
  U as applyArrayDiffOperations,
  F as areArraysEqual,
  Q as arrayDiffOperations,
  M as arrayHasValues,
  _ as arrayHead,
  J as arrayOfIterableIterator,
  k as arrayTail,
  H as compareArrays,
  $ as concatArrays,
  R as createFilledArray,
  x as filterArray,
  b as filterMapArray,
  j as filterNullsFromArray,
  B as flatMapArray,
  D as flattenArray,
  P as foldLeftArray,
  T as forEachElement,
  g as generateArray,
  L as generateSequenceArray,
  I as isArrayEmpty,
  X as joinArrayWithConjunction,
  q as mapArray,
  Y as rankArray,
  z as removeAllFromArray,
  G as removeAllFromArrayByPredicate,
  E as removeOneFromArray,
  w as removeOneFromArrayByPredicate,
  C as sortArray,
  W as uniqueByPrimitive,
  V as uniquePrimitives
};
